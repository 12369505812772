import React, { useEffect, useRef, useState } from "react";
import { HomeAnimStyle } from "../styles/HomeAnimeStyle";
import Image from "./Image";
import Ball from "./Ball";
import useInterval from "react-useinterval";
import SanityBlockContent from "@sanity/block-content-to-react";
import { useTranslation } from "react-i18next";
import { useStaticQuery, graphql } from "gatsby";
import { useGlobalData } from "../context/summerOrWinterContext";

const LinkRenderer = ({ mark: { href }, children }) => (
  <a href={href} target='_blank' rel='noopener noreferrer'>
    {children}
  </a>
);

const serializers = {
  marks: {
    link: LinkRenderer,
  },
};

const HOME_PLACES = [
  "Saal",
  "Klub",
  "Biergarten",
  "Weingarten",
  "Körnervilla",
  "Eisdiele",
  "Turmhaus",
];

const MOBILE_INTERVAL = 1300;

const HomeAnim = ({ data, mobile }) => {
  const site = mobile
    ? useStaticQuery(graphql`
        query {
          queryHome: allSanityHome {
            nodes {
              place
              id
              image {
                asset {
                  fluid(maxWidth: 300) {
                    ...GatsbySanityImageFluid
                    base64
                  }
                }
              }
            }
          }
        }
      `)
    : null;

  const [showee, setShowee] = useState("Zenner");
  const [counter, setCounter] = useState(0);
  const { t } = useTranslation();
  const homeRef = useRef(null);

  const cycleShowee = () => {
    const newCounter = counter <= HOME_PLACES.length - 1 ? counter + 1 : 0;
    setCounter(newCounter);
    setShowee(newCounter === 0 ? "Zenner" : HOME_PLACES[counter]);
  };

  const handleMouseMove = ({ target: { classList } }) =>
    setShowee(classList[0] === "hover" ? "Zenner" : `${classList[0]}`);

  useInterval(cycleShowee, mobile ? MOBILE_INTERVAL : null);

  useEffect(() => {
    const home = homeRef.current;
    if (home) {
      home.addEventListener("mousemove", handleMouseMove);
      return () => home.removeEventListener("mousemove", handleMouseMove);
    }
  }, []);

  const dataNodes = mobile ? site.queryHome.nodes : data.queryHome.nodes;
  const { summerOrWinter } = useGlobalData();

  const getPlaceName = (place) => {
    switch (place) {
      case "Zenner":
        return t("welcome");
      case "Biergarten":
        return summerOrWinter[0]?.summerOrWinter === "Summer"
          ? "Biergarten"
          : "Wintergarten";
      default:
        return place;
    }
  };

  return (
    <div className='home' ref={homeRef}>
      {!mobile && data?.queryNews?.nodes[0]?.newsShow && (
        <div className='news-box'>
          <p className='marque'>{data?.queryNews?.nodes[0]?.newsActu}</p>{" "}
        </div>
      )}

      {dataNodes.map((homeElem) => (
        <HomeAnimStyle
          place={homeElem.place}
          key={homeElem.id}
          show={showee === homeElem.place}
          newsOn={data?.queryNews?.nodes[0]?.newsShow}
        >
          <div className='imgBox'>
            {homeElem.image && <Image image={homeElem.image} />}
          </div>
          {mobile &&
            data?.queryNews?.nodes[0]?.newsShow &&
            data?.queryNews?.nodes[0]?._rawText && (
              <div className='actu-mobile'>
                <SanityBlockContent
                  blocks={data?.queryNews?.nodes[0]?._rawText}
                  projectId='2le2eemj'
                  dataset='production'
                  serializers={serializers}
                />
              </div>
            )}
          <div className='titleBox'>
            <h2>{getPlaceName(homeElem.place)}</h2>
            <div className='ballBox'>
              <Ball
                place={homeElem.place}
                summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              />
            </div>
          </div>
        </HomeAnimStyle>
      ))}
      <div className='hover'>
        {HOME_PLACES.concat("Zenner").map((place) => (
          <div key={place} className={place} />
        ))}
      </div>
    </div>
  );
};

export default HomeAnim;
