import React from "react";
import CheckboxStyle from "../styles/CheckboxStyle";

const Checkbox = ({ setCheck, status, title }) => {
  const handleChange = (e) => {
    e.preventDefault();
    setCheck();
  };

  return (
    <CheckboxStyle>
      <label className='switch'>
        <input type='checkbox' checked={status} onChange={handleChange} />
        <span className='slider round' />
      </label>
      <a href='#' onClick={handleChange}>
        <h2 className={!status ? "grey" : "blue"}>{title}</h2>
      </a>
    </CheckboxStyle>
  );
};

export default Checkbox;
