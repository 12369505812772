import styled from "styled-components";

export const BallStyle = styled.div`
  border-radius: 50%;
  width: 100%;
  padding-bottom: ${(props) => (props.img ? "0%" : "100%")};
  margin-top: var(--marginvw);
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    height: auto;
  }

  &.small {
    margin-top: 0rem;
    width: 1.4vw;
    height: 1.4vw;
    padding-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      height: auto;
    }
  }

  background: ${(props) => {
    if (props.place === "Turmhaus") {
      return "var(--Turmhaus)";
    }
    if (props.place === "Saal") {
      return "var(--Saal)";
    }
    if (props.place === "Klub") {
      return "var(--Klub)";
    }

    if (props.place === "Biergarten" && props.summerOrWinter === "Summer") {
      return "var(--Biergarten)";
    } else if (
      props.place === "Biergarten" &&
      props.summerOrWinter === "Winter"
    ) {
      return "var(--Wintergarten)";
    }
    if (props.place === "Eisdiele") {
      return "var(--Eisdiele)";
    }
    if (props.place === "Körnervilla") {
      return "var(--Kornervilla)";
    }
    if (props.place === "Weingarten") {
      return "var(--Weingarten)";
    }
    if (props.place === "Pop-Up Bistro") {
      return "var(--Pop-up-bistro)";
    }
  }};
`;
