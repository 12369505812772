import React, { useState } from "react";
import { InfoColumnStyle } from "../styles/InfoColumnStyle";

import InfoBox from "./InfoBox";

import TopBarColumn from "./TopBarColumn";
import { useTranslation } from "react-i18next";

export default function InfoColumn({
  place,
  data,
  setBigScreen,
  bigScreen,
  setBigPlace,
  bigPlace,
}) {
  const [overColumn, setOverColumn] = useState(false);

  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    setBigScreen(!bigScreen);
    setBigPlace(place);
  };

  return (
    <InfoColumnStyle
      className='column'
      big={bigPlace === place && bigScreen}
      notHide={!(bigScreen && bigPlace !== place)}
      place={place}
      onMouseOver={(e) => !bigScreen && setOverColumn(true)}
      onMouseOut={(e) => !bigScreen && setOverColumn(false)}
      overColumn={overColumn}
    >
      <TopBarColumn
        place={place}
        bigPlace={bigPlace}
        overColumn={overColumn}
        setOverColumn={setOverColumn}
        bigScreen={bigScreen}
        handleClick={handleClick}
      />

      {data && place === "About" && data.queryAbout && (
        <InfoBox
          elem={data.queryAbout.nodes[0]}
          location='About'
          handleClick={handleClick}
        />
      )}
      {data && place === "Info/Kontakt" && data.queryKontakt && (
        <InfoBox
          elem={data.queryKontakt.nodes[0]}
          location={t("info / contact")}
          handleClick={handleClick}
        />
      )}
      {data && place === "Historie" && data.queryHistory && (
        <InfoBox
          elem={data.queryHistory.nodes[0]}
          location='Historie'
          handleClick={handleClick}
        />
      )}
    </InfoColumnStyle>
  );
}
