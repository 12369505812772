import React, { useContext, useEffect } from "react";
import { Highlight } from "react-instantsearch-dom";
import { ListBoxStyle } from "../styles/ListBoxStyle";
import Ball from "./Ball";
import { useTranslation } from "react-i18next";
import { TitleContext } from "../context/titleContext";
import { navigate } from "gatsby";
import { useGlobalData } from "../context/summerOrWinterContext";

export default function ListSearch({ hit }) {
  const { t, i18n } = useTranslation();
  const { setTitle, title } = useContext(TitleContext);
  const { summerOrWinter } = useGlobalData();

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  let dateMonth, dateDay, dateHour, dateMinutes, eventDate;

  if (hit.eventDate) {
    eventDate = new Date(hit.eventDate);
    dateMonth = addZero(eventDate.getMonth() + 1);
    dateDay = addZero(eventDate.getDate());
    dateHour = addZero(eventDate.getHours());
    dateMinutes = addZero(eventDate.getMinutes());
  }

  const noShow = () => {
    const currentDate = new Date();
    const diffTime = currentDate - eventDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays >= 2;
  };

  useEffect(() => {
    localStorage.setItem("title", title);
  }, [title]);

  return (
    <ListBoxStyle noShow={noShow()}>
      {hit.source === "kultur" && (
        <a
          href='/programm'
          target='_blank'
          onClick={(e) => {
            e.preventDefault(); // Prevent the default action (navigating to the new page)
            setTitle(hit.title); // Set the title
            localStorage.setItem("title", hit.title); // Save the title in local storage
            navigate("/programm"); // Navigate to the new page
          }}
        >
          <div className='line-list'>
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={hit.place}
            />
            <>
              <h4>
                {dateDay}/{dateMonth}
              </h4>
              <h4 className='hour'>
                {dateHour}:{dateMinutes}
              </h4>
            </>
            <h4>
              <Highlight hit={hit} attribute='title' tagName='mark' />
            </h4>
            <h4>
              <Highlight hit={hit} attribute='place' tagName='mark' />
            </h4>
            <h4>
              <Highlight hit={hit} attribute='typeOfEvent' tagName='mark' />
            </h4>
            <a href={hit.linkEvent} target='_blank'>
              <h4 className='ticket'>
                Ticket <span className='Zarrows'>➝</span>
              </h4>
            </a>
          </div>
        </a>
      )}
      {hit.source === "kulinarik" && (
        <a href={`/menu/${hit.place.toLowerCase()}`}>
          <div className='line-list'>
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={hit.place}
            />
            <h4></h4>
            <h4></h4>
            <h4>
              <Highlight hit={hit} attribute='title' tagName='mark' />
            </h4>
            <h4>
              <Highlight hit={hit} attribute='place' tagName='mark' />
            </h4>
            <h4></h4>
            <h4 className='ticket'>
              {t("menu")} <span className='Zarrows'>➝</span>
            </h4>
          </div>
        </a>
      )}
      {hit.source === "location" && (
        <a href={`/location`}>
          <div className='line-list'>
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={hit.place}
            />
            <h4></h4>
            <h4></h4>
            <h4>
              <Highlight hit={hit} attribute='title' tagName='mark' />
            </h4>
            <h4>
              <Highlight hit={hit} attribute='place' tagName='mark' />
            </h4>
            <h4></h4>
          </div>
        </a>
      )}
      {hit.source === "mieten" && (
        <a href={`/mieten`}>
          <div className='line-list'>
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={hit.place}
            />
            <h4></h4>
            <h4></h4>
            <h4>
              <Highlight hit={hit} attribute='title' tagName='mark' />
            </h4>
            <h4>
              <Highlight hit={hit} attribute='place' tagName='mark' />
            </h4>
            <h4></h4>
          </div>
        </a>
      )}
    </ListBoxStyle>
  );
}
