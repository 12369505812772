import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  // Define the resources for each language
  en: {
    translation: require("./src/locales/en/translation.json"),
  },
  // Add more languages as needed
  de: {
    translation: require("./src/locales/de/translation.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "de", // Set the default language
  fallbackLng: "de", // Set the fallback language
  interpolation: {
    escapeValue: false, // React already escapes the values
  },
});

export default i18n;
