import React from "react";
import { TopBarColumnStyle } from "../styles/TopBarColumnStyle";
import { useGlobalData } from "../context/summerOrWinterContext";
import { t } from "i18next";

export default function TopBarColumn({
  place,
  overColumn,
  setOverColumn,
  bigScreen,
  bigPlace,
  handleClick,
  kultur,
}) {
  const { summerOrWinter } = useGlobalData();

  // Determines the display text based on place and season
  const getDisplayText = (place) => {
    if (place !== "Biergarten") return place;
    return summerOrWinter[0]?.summerOrWinter === "Summer"
      ? "Biergarten"
      : "Wintergarten";
  };

  // Handles mouse over and out behavior
  const toggleOverColumn = (state) => {
    if (bigScreen) {
      setOverColumn(state);
    }
  };

  return (
    <TopBarColumnStyle
      big={bigPlace === place && bigScreen}
      onClick={handleClick}
      // Removed onMouseOver and onMouseOut handlers for cleaner refactoring. If needed, they can be reinstated with toggleOverColumn.
    >
      {overColumn && !bigScreen && (
        <a href='#' onClick={handleClick}>
          <span className='arrows-open'>{kultur ? "·" : "↔"}</span>
        </a>
      )}
      {!overColumn && !bigScreen && <h2>{getDisplayText(place)}</h2>}
      {bigScreen && (
        <>
          <h2>{kultur ? "Programm" : getDisplayText(place)}</h2>
          <a href='#' onClick={handleClick}>
            <span className='arrows-open'>{kultur ? "·" : "↔"}</span>
          </a>
        </>
      )}
    </TopBarColumnStyle>
  );
}
