import { useEffect, useState } from "react";
import { globalHistory } from "@reach/router";

export default function usePreviousLocation() {
  const [locationPaths, setLocationPaths] = useState([
    globalHistory.location.pathname,
  ]);

  useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      if (action === "PUSH") {
        setLocationPaths([locationPaths[0], location.pathname]);
      }
    });
  }, [locationPaths]);

  return {
    locationPath: locationPaths[1] || locationPaths[0],
    prevLocationPath: locationPaths[0] || null,
  };
}
