import React from "react";
import ballZenner from "../assets/zenner-elements/dot-zenner.png";
import { BallStyle } from "../styles/BallStyle";

export default function Ball({ place, size, summerOrWinter }) {
  return (
    <BallStyle
      summerOrWinter={summerOrWinter}
      className={size}
      place={place}
      img={place.toLowerCase() === "zenner"}
    >
      {place.toLowerCase() === "zenner" && <img src={ballZenner} alt='' />}
    </BallStyle>
  );
}
