import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import { Link } from "gatsby";
import Image from "./Image";
import Ball from "./Ball";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ContentBoxStyle } from "../styles/ContentBoxStyle";
import ReadMoreBox from "./ReadMoreBox";
import { useTranslation } from "react-i18next";
import { useGlobalData } from "../context/summerOrWinterContext";

export default function KulturContentBox({
  elem,
  threeEventsOnly,
  mobile,
  numColumn,
}) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [readMore, setReadMore] = useState(false);
  const { summerOrWinter } = useGlobalData();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  const eventDate = new Date(elem.eventDate);
  const currentDate = new Date();

  const dateMonth = addZero(eventDate.getMonth() + 1);
  const dateDay = addZero(eventDate.getDate());
  const dateHour = addZero(eventDate.getHours());
  const dateMinutes = addZero(eventDate.getMinutes());

  const noShow = () => {
    const diffTime = currentDate - eventDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert difference in milliseconds to days
    return diffDays >= 2; // Return true if eventDate was at least two days ago
  };

  return (
    <ContentBoxStyle
      kultur
      numColumn={numColumn}
      hide={noShow()}
      id={elem.title}
    >
      {elem.image && <Image image={elem.image} />}

      <div className='event'>
        <div className='event-box'>
          <div className='event-info'>
            <h4>
              {dateDay}/{dateMonth}
            </h4>
            <h4 className='padding-left'>
              {dateHour}:{dateMinutes}
            </h4>
            <h4>{elem.typeOfEvent}</h4>
          </div>
          <h3 className='event-name'>{elem.title}</h3>
        </div>
      </div>

      <ReadMoreBox
        readMore={readMore}
        setReadMore={setReadMore}
        elem={elem}
        mobile={mobile}
      />

      <a className='button' href={`${elem.linkEvent}`} target='_blank'>
        <Ball
          size='small '
          place={elem.place}
          summerOrWinter={summerOrWinter[0]?.summerOrWinter}
        />
        <h4>
          Ticket <span className='Zarrows'>➝</span>
        </h4>
      </a>

      {threeEventsOnly && (
        <>
          <Link
            className='button'
            to={i18n.language === "en" ? `/en/programm` : `/programm`}
          >
            <Ball size='small ' place={elem.place} />
            <h4>
              {t("program")} <span className='Zarrows'>➝</span>
            </h4>
          </Link>
          <Ball
            place={elem.place}
            summerOrWinter={summerOrWinter[0]?.summerOrWinter}
          />
        </>
      )}
    </ContentBoxStyle>
  );
}
