import styled from "styled-components";

export const FormStyle = styled.form`
  overflow: scroll;
  height: calc(100% - var(--marginvw) * 2);
  border: none;
  border-left: 0.5px solid var(--blue);
  color: var(--blue);
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  #time:before {
    content: "Time:";
    margin-right: 0.6em;
    color: #9d9d9d;
  }

  fieldset {
    border: none;
    padding: 0;
    button {
      width: auto;
      margin-top: 10px;
      margin: 10px auto 0 auto;
      display: block;
      padding-right: var(--marginvw);
      padding-left: var(--marginvw);
      border: 0.5px solid var(--blue);
      border-radius: 100px;
      background-color: white;
      cursor: pointer;
      &:hover {
        background-color: var(--blue);
        color: white;
      }
    }
  }

  .topBox {
    cursor: pointer;
    height: 3vh;
    text-align: ${(props) => (props.big ? "end" : "center")};
    border-bottom: 0.5px solid var(--blue);
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    text-align: center;

    padding-bottom: var(--marginvw);
    display: flex;
    button {
      margin: 0;
    }
    h2 {
      flex-grow: 1;
      align-items: center;
    }
  }

  .info-box {
    margin: 3rem 40rem 3rem 0;
  }

  .info-text {
    padding-top: 1.5rem;
  }

  label {
    width: 100%;
    color: var(--grey);
  }

  .check-place {
    input {
      margin-bottom: 0;
    }
  }

  /* select {
    color: var(--grey);
    opacity: 1;
  }
  select option {
    color: var(--blue);
  } */

  select {
    color: var(--grey);
  }
  option {
    color: var(--blue);
  }
  /* option:first-child {
    color: var(--grey);
  } */
  /* select option:first-child {
    color: #00ff00;
  } */

  input,
  select,
  textarea,
  label {
    font-size: 0.9vw !important;
    width: 100%;
    margin-bottom: 1.3vw;
    border: 0;
    border-bottom: 0.5px solid var(--blue);
    background-color: white;

    ::placeholder {
      color: var(--grey);
      opacity: 1;
    }
  }
  label {
    border-bottom: 0;
  }

  input {
    color: var(--blue);
  }

  h3 {
    margin-top: 3rem;
  }

  textarea {
    ::placeholder {
      color: var(--grey);
      opacity: 100;
    }
    overflow: auto;
    width: 100%;
    height: 20vh;
    border: none;
    border-bottom: 0.5px solid var(--blue);
    color: var(--blue);
  }

  .close {
    background-color: white;
    border: 0.5px solid var(--blue);
    border-radius: 50%;
    font-size: 1.35vw;
    width: 1.5vw;
    height: 1.5vw;
    line-height: 0%;
    padding: 0 !important;
    padding-bottom: 2px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    :hover {
      background-color: var(--blue);
      color: white;
    }
  }

  .check-place {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--marginvw);
    margin-bottom: 1vw;
    label {
      color: var(--blue);
      width: calc(100% / 7 - var(--marginvw) * 5);
    }

    input {
      width: auto;
      display: none;
      margin-right: var(--marginvw);
    }
    .box-check {
      display: flex;
      justify-content: center;
      border: 0.5px solid var(--grey);
      padding: 0.5vw;
      color: var(--grey);
      cursor: pointer;
    }

    input:checked + .box-check {
      border: 0.5px solid var(--blue);
      color: var(--blue);
      background-color: #f9f9f9;
      -webkit-box-shadow: 2px 2px 3px -3px var(--blue);
      box-shadow: 2px 2px 3px -3px var(--blue);
    }
  }

  p,
  a {
    font-size: 0.9vw;
  }

  a:hover {
    text-decoration: underline;
  }
  @media (max-width: 900px) {
    input,
    p,
    textarea,
    select,
    .box-check {
      margin-bottom: 4vw;
    }

    input,
    textarea,
    select {
      width: 95%;
    }

    .box-check p {
      margin: 1vh 0;
    }

    button p {
      margin-bottom: inherit;
    }
    input,
    select,
    textarea,
    label {
      font-size: var(--p) !important;
    }
    border: 0;
    margin: var(--marginvw) 0;
    .topBox {
      display: none;
    }

    .info-box {
      margin: 0;
      margin-bottom: var(--marginvw);
    }

    p,
    label {
      font-size: var(--p);
    }

    .check-place {
      display: block;
    }
  }
`;
