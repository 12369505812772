import { createGlobalStyle } from "styled-components";
// import bg from '../assets/images/bg.svg';
// import stripes from '../assets/images/stripes.svg';

const GlobalStyles = createGlobalStyle`
  :root {
  --Weingarten: #FF4C1B;
  --Saal: #001D31;
  --Klub: #AF9767;
  --Biergarten: #8CB8C3;
  --Kornervilla: #FFEF47;
  --Eisdiele:#F4D9D8;
  --Turmhaus: #A7A8B0;
  --Pop-up-bistro: linear-gradient(to bottom right, white, #FF4C1B);
  --Wintergarten: linear-gradient(to bottom right, #5181c3, #FF4C1B);
  --blue: #001D31;
  --filtersFont: 2rem;   
  --h2: 1.35vw;
  --h3: 1.35vw;
  --h4: 1.4vw;
  --p: 1vw; 
  --grey: #c2c3c7;

  --marginvw: 0.5vw;

  }


  body {
    color: var(--blue);
    scrollbar-color: #cfcfcf white;
    scrollbar-width: thin;
    font-variant-ligatures: none;
  }


  .hidden {
    display: none !important;
  }

  p, h1, h2, h3, h4, h5, ul {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  /* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: smooth;
    margin-bottom: var(--marginvw);
  } */


  a {
    color: var(--blue);
  }
  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #ffffff;
}

::-webkit-scrollbar-thumb {
    background: #dcdbdb;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px ;
}

::-webkit-scrollbar-corner {
    background: #b3b3b3;
}
 
/* .ais-Highlight {
  background-color: blue;
} */

.search {
  width: 100%;
  border-left: 0.5px solid var(--blue);
}
.ais-SearchBox-form {
  display: flex;
  margin-bottom: var(--marginvw);
  padding: 0 var(--marginvw) 0 var(--marginvw);
  align-items: center;
}
.ais-SearchBox {
  width: 100%;
}

.ais-SearchBox-submit {
  display: none;
}





  .ais-Highlight-highlighted{
    background-color: #eae6fa;;
    
  }

  input[type="date"] {
    position: relative;
  }

  input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
input[type="date"]::-webkit-calendar-picker-indicator {
 opacity: 0;
  background-color: transparent;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: end;
 
  
}

input[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
  width:  100%;
  border: 0px;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
  text-align: center;
  border-bottom: 0.5px solid var(--blue);
  padding: var(--marginvw) 0;
}

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

select {
  opacity: 1;
    color: var(--grey);
  }
  option {
    color: var(--blue);
  }

.error {
  color: red;
}

.success {
  color: green;
  /* margin: 3rem 40rem 3rem 0px; */
}

.arrow {
  display: none;
}
body::-webkit-scrollbar {
    width: 0.5vw;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: #750000 !important;
    outline: 1px solid slategrey;
  }

  .ais-SearchBox-reset{
    background-color: white;
    border: none;
    width: 5vw;
border: 0.5px solid;
cursor: pointer;
  background-color: white;
    border: 0.5px solid var(--blue);
    border-radius: 50%;
    font-size: 1.3rem;
  width: 1.5vw;
    height: 1.5vw;
    line-height: 0%;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    :hover {
      background-color: var(--blue);
      color: white;
    }
  }

  .top-bar-big {
    cursor: pointer;
  }
  @media (max-width: 900px) {

.ais-SearchBox-reset{
  cursor: pointer;
  background-color: white;
  border: 0.5px solid var(--blue);
  border-radius: 50%;
  color: #001D31;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent content from overflowing */

  .ais-SearchBox-resetIcon {
    fill: #001D31; /* SVG fill color */
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
  }

  &:hover {
    background-color: var(--blue);
    color: white;

    .ais-SearchBox-resetIcon {
      fill: white; /* Change SVG fill color on hover */
    }
  }
}

  
  /* button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
} */
  :root {
      --h2: 8vw;
      --h3: 6vw;
      --h4: 4vw;
      --p: 4vw;
      --marginvw: 1rem; 
      }

  }

`;

export default GlobalStyles;
