import React, { useEffect } from "react";
import "normalize.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, navigate } from "gatsby";
import { MenuMobilStyle } from "../styles/MenuMobilStyle";
import logoZenner from "../assets/zenner-elements/zenner-logo.png";
import { useTranslation } from "react-i18next";

export default function MenuMobil({ location }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.getElementById("menu-toggle").checked = false;
  }, [location]);

  const navigateWithLocale = (path) => {
    const newPath =
      path.startsWith("/en") || i18n.language !== "en" ? path : `/en${path}`;
    navigate(newPath);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const currentPath = location.pathname;
    const newPath =
      currentPath.startsWith("/en") && lng !== "en"
        ? currentPath.slice(3)
        : currentPath;
    navigateWithLocale(newPath);
  };

  const renderLink = (path, label) => (
    <Link to={i18n.language === "en" ? `/en${path}` : `${path}`}>
      <h2>{t(label)}</h2>
    </Link>
  );

  const renderChangeLanguageButton = (lng, label) => (
    <a
      href='#'
      onClick={(e) => {
        e.preventDefault();
        changeLanguage(lng);
      }}
      target='_blank'
      className={i18n.language !== lng ? "active" : ""}
    >
      <p>{label}</p>
    </a>
  );

  return (
    <MenuMobilStyle>
      <section className='top-nav'>
        <Link to={i18n.language === "en" ? "/en" : "/"}>
          <img
            className='logo'
            src={logoZenner}
            alt=''
            width='100%'
            height='100%'
          />
        </Link>

        <input id='menu-toggle' type='checkbox' />
        <label className='menu-button-container' htmlFor='menu-toggle'>
          <div>
            <FontAwesomeIcon icon={faBars} size='2x' />
          </div>
        </label>

        <ul className='menu'>
          <div className='menu-list'>
            {renderLink("/location", "location")}
            {renderLink("/programm", "program")}
            {renderLink("/kulinarik", "culinary")}
            {renderLink("/mieten", "rent")}
          </div>

          <div className='footer'>
            <div className='info-kontakt'>
              {renderLink("/search", "search")}
              {renderLink("/info-kontakt", "info / contact")}
              {renderLink("/info-kontakt", "imprint")}
            </div>
            <div className='socialLinks'>
              {renderChangeLanguageButton("de", "DE")}
              {renderChangeLanguageButton("en", "EN")}
            </div>
          </div>
        </ul>
      </section>
    </MenuMobilStyle>
  );
}
