// src/contexts/GlobalDataContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

const GlobalDataContext = createContext();

export const GlobalDataProvider = ({ children }) => {
  const [summerOrWinter, setSummerOrWinter] = useState([]);

  const data = useStaticQuery(graphql`
    query SummerOrWinter {
      allSanityShowPlacesKulinarik {
        nodes {
          summerOrWinter
        }
      }
    }
  `);

  useEffect(() => {
    if (data.allSanityShowPlacesKulinarik.nodes.length > 0) {
      setSummerOrWinter(data.allSanityShowPlacesKulinarik.nodes);
    }
  }, [data]);

  return (
    <GlobalDataContext.Provider value={{ summerOrWinter }}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = () => useContext(GlobalDataContext);
