import React, { useState } from "react";

import SanityBlockContent from "@sanity/block-content-to-react";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import { Link } from "gatsby";
import { ContentBoxStyle } from "../styles/ContentBoxStyle";
import Image from "./Image";
import Ball from "./Ball";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useSendNewsletter from "../utils/useSendNewsletter";
import useForm from "../utils/useForm";
import useEmail from "../utils/useEmail";
import ReadMoreBox from "./ReadMoreBox";
import { useTranslation } from "react-i18next";

function MyLinkRenderer(props) {
  return (
    <a href={props.mark.href} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  );
}

const serializers = {
  marks: {
    link: MyLinkRenderer,
  },
};

export default function InfoBox({ elem, location, mobile, handleClick }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [readMore, setReadMore] = useState(false);
  const { values, updateValue } = useForm({
    emailNews: "",
    fname: "",
    lname: "",
    email: "",
    name: "",
    text: "",
    anlass: "Anlass",
  });
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { messageSuccessNews, errorNews, loadingNews, submitNewsletter } =
    useSendNewsletter({
      values,
    });

  const { messageSuccess, error, loading, submitMessage } = useEmail({
    values,
    formName: "senEmailInfo",
  });

  // if (messageSuccess) {
  //   return <p>{messageSuccess}</p>;
  // }

  return (
    <ContentBoxStyle>
      {location === "About" && (
        <>
          <h3>{elem?.title}</h3>
          {elem?._rawText && (
            <SanityBlockContent
              blocks={elem?._rawText}
              projectId='2le2eemj'
              dataset='production'
              serializers={serializers}
            />
          )}
          <Link
            className='button'
            to={i18n.language === "en" ? "/en/mietanfrage" : "/mietanfrage"}
          >
            <Ball size='small ' place='Saal' />
            <h4>
              {t("mietanfrage")}
              <span className='Zarrows'>➝</span>
            </h4>
          </Link>
          {/* <Link className='button' to='/catering'>
            <Ball size='small ' place='Saal' />
            <h4>
              Catering <span className='Zarrows'>➝</span>
            </h4>
          </Link> */}
          <Link
            className='button'
            to={i18n.language === "en" ? "/en/map" : "/map"}
          >
            <Ball size='small ' place='Saal' />
            <h4>
              Zenner map <span className='Zarrows'>➝</span>
            </h4>
          </Link>
        </>
      )}
      {location === t("info / contact") && (
        <>
          <h3>{elem?.title}</h3>
          {elem?._rawText && (
            <SanityBlockContent
              blocks={elem?._rawText}
              projectId='2le2eemj'
              dataset='production'
              serializers={serializers}
            />
          )}

          <form onSubmit={submitNewsletter} className='form-info'>
            <fieldset disabled={loadingNews}>
              <input
                type='text'
                placeholder={t("family name")}
                name='fname'
                value={values.fname}
                onChange={updateValue}
              />
              <input
                type='text'
                placeholder='Name'
                name='lname'
                value={values.lname}
                onChange={updateValue}
              />
              <input
                type='text'
                placeholder='Email'
                name='emailNews'
                value={values.emailNews}
                onChange={updateValue}
              />{" "}
            </fieldset>
            <div>
              {errorNews ? <p className='error'>Error: {errorNews}</p> : ""}
              {messageSuccessNews ? (
                <p className='success'>{t("success newsletter")}</p>
              ) : (
                ""
              )}
            </div>
            <fieldset disabled={loadingNews}>
              <button
                className='senden'
                type='submit'
                disabled={loadingNews || messageSuccessNews}
              >
                {loadingNews ? (
                  <p>{t("sending email")}</p>
                ) : (
                  <p>{t("senden")}</p>
                )}
              </button>
            </fieldset>
          </form>

          <h3>E-MAIL</h3>
          <form onSubmit={submitMessage}>
            <fieldset disabled={loading}>
              <select
                value={values.anlass}
                name='anlass'
                placeholder='Anlass'
                onChange={updateValue}
              >
                <option> {t("occasion")}</option>
                {elem?.reasonsList.map((reason) => (
                  <option value={reason} key={uuidv4()}>
                    {reason}
                  </option>
                ))}
              </select>

              <input
                type='text'
                name='name'
                placeholder='Name'
                id='name'
                value={values.name}
                onChange={updateValue}
              />

              <input
                type='email'
                name='email'
                placeholder='Email'
                id='email'
                value={values.email}
                onChange={updateValue}
              />
              <textarea
                name='text'
                id='text'
                value={values.text}
                onChange={updateValue}
                placeholder='Text'
                className='text'
              />
            </fieldset>
            <div>
              {error ? (
                <p className='error'>Error: {t("missing field")}</p>
              ) : (
                ""
              )}
              {messageSuccess ? (
                <p className='success'>{t("sucess send")}</p>
              ) : (
                ""
              )}
            </div>
            <fieldset disabled={loading}>
              {/* <div>
                {error ? (
                  <p className='error'>Error: {t("missing field")}</p>
                ) : (
                  ""
                )}
                {messageSuccess ? (
                  <p className='success'>{t("sucess send")}</p>
                ) : (
                  ""
                )}
              </div> */}
              {!messageSuccess && (
                <button
                  className='senden'
                  type='submit'
                  disabled={loading || messageSuccess}
                >
                  {loading ? <p>{t("sending email")}</p> : <p>{t("senden")}</p>}
                </button>
              )}
            </fieldset>
          </form>
        </>
      )}

      {(location === "About" || location === t("info / contact")) && (
        <>
          {elem?.openingInfos.map((info) => (
            <div className='info-box' key={uuidv4()}>
              <h5>{info.title}</h5>
              {info._rawText &&
                (info.title === "DATENSCHUTZ" ||
                info.title === "RECHTLICHE HINWEISE" ||
                info.title === "LEGAL NOTES" ||
                info.title === "DATA PROTECTION" ? (
                  <ReadMoreBox
                    readMore={readMore[info.title]} // Changed this line
                    setReadMore={(state) =>
                      setReadMore({ ...readMore, [info.title]: state })
                    } // Changed this line
                    elem={info}
                    title={info.title}
                  />
                ) : (
                  <SanityBlockContent
                    blocks={info._rawText}
                    projectId='2le2eemj'
                    dataset='production'
                    serializers={serializers}
                  />
                ))}

              {(elem.pdfUrl || info.manuscript) && (
                <a
                  href={elem.pdfUrl ? elem.pdfUrl : info.manuscript?.asset.url}
                  rel='noopener noreferrer'
                  download='MyDocument.pdf'
                  target='_blank'
                >
                  {t("pdf")}
                </a>
              )}
            </div>
          ))}
        </>
      )}

      {location === "Historie" && (
        <>
          <h3>{elem?.title}</h3>

          <Slider {...settings}>
            {elem?.images &&
              elem?.images.map(
                (image) =>
                  image.asset && (
                    <div
                      onClick={(e) => (!mobile ? handleClick(e) : "")}
                      key={uuidv4()}
                    >
                      <Image image={image} carousselType='landscape' />
                    </div>
                  )
              )}
          </Slider>
          {elem?._rawText && (
            <SanityBlockContent
              blocks={elem?._rawText}
              projectId='2le2eemj'
              dataset='production'
              serializers={serializers}
            />
          )}
        </>
      )}
    </ContentBoxStyle>
  );
}
