import styled from "styled-components";

export const GridMobilStyle = styled.div`
  display: block;
  position: relative;
  height: 100%;

  .top-bar {
    border-bottom: 0.5px solid var(--blue);
    padding: var(--marginvw) var(--marginvw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: calc(1.5rem + 2rem);
    z-index: 3;
    background-color: white;
    height: var(--marginvw);
  }

  .ais-Hits-list {
    padding: 0;
    cursor: pointer;
    li {
      margin-bottom: 0.1vw !important;
    }
  }

  .home {
    display: flex;
    height: 80vh;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  .small {
    width: 1.3rem;
    height: 1.3rem;
  }
  .actu-mobile {
    text-transform: uppercase;
    font-size: var(--h4);
    font-style: italic;
    padding-top: var(--marginvw);
    border-top: 1.5px solid var(--blue);
    margin-top: calc(var(--marginvw) * 2);
    padding: var(--marginvw);
    color: #ff6c00;
  }

  .event-info {
    padding-bottom: 0.5rem !important;
  }
  .event-info,
  .event-name {
    margin-bottom: 0.5rem;
  }
  .button {
    /* padding-top: 0.5rem !important; */
    color: var(--blue) !important;
    background-color: white !important;
  }

  button {
    p {
      background-color: white !important;
      color: var(--blue) !important;
    }
  }

  .hide {
    display: none;
  }
  .fa-1x {
    font-size: 1.5em;
  }
  .slick-arrow {
    margin-top: -1.5vh;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-next {
    right: 0;
    ::before {
      content: ">";
    }
  }
  .slick-prev {
    z-index: 1;
    left: 0px;
    ::before {
      content: "<";
    }
  }

  .slick-dots {
    bottom: 28px;
  }

  .slick-dots li button::before {
    opacity: 1;
    color: white;
    font-size: 10px;
  }

  .slick-dots li {
    width: 3px;
  }
  .slick-dots li.slick-active button::before {
    color: var(--blue);
  }
  .slick-prev,
  .slick-next {
    width: auto;
    height: auto;
  }
  .slick-prev::before,
  .slick-next::before {
    opacity: 0;
    /* width: 1.5vw;
    height: 1.5vw; */
    font-size: 4vh;
    display: flex;
    color: var(--blue);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .btn-cathering {
    transform: rotate(-90deg);
    z-index: 22222222222222;
    height: 11.5vw;
    transform-origin: unset;
    width: fit-content;
    position: fixed;
    left: -15%;
    top: 50vh;
    background-color: var(--blue);
    padding: 1.5vw 6.5vw;
    border-radius: 5vw;
    color: white;
    border: 0.5px solid white;
    text-transform: uppercase;
    :hover {
      color: var(--blue);
      background-color: white;
      border: 0.5px solid var(--blue);
    }
    h3 {
      font-size: 4vw;
      padding-top: 5vw;
    }
  }

  .btn-cathering.events {
    left: -15vw;
  }

  .close {
    background-color: white;
    border: 0.5px solid var(--blue);
    border-radius: 50%;
    font-size: 5vw;
    width: 6vw;
    height: 6vw;
    line-height: 0%;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    :hover {
      background-color: var(--blue);
      color: white;
    }
  }
`;
