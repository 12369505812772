import { useState } from "react";

export default function useEmail({ values, formName, validateForm }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function submitMessage(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setErrorMessage(null);

    // if (!validateForm()) {
    //   setLoading(true);
    // } else {
    //   setErrorMessage(validateForm());
    //   return;
    // }

    const body = values;

    let sendEmail;

    if (formName === "Catering") {
      sendEmail = "sendEmailCatering";
    } else if (formName === "Mietanfrage") {
      sendEmail = "sendEmailMietanfrage";
    } else {
      sendEmail = "sendEmailInfo";
    }
    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/${sendEmail}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const responseText = await res.text();
    const text = responseText ? JSON.parse(responseText) : {};

    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setMessageSuccess("Success!");
    }
  }

  return {
    messageSuccess,
    error,
    loading,
    errorMessage,
    submitMessage,
  };
}
