import styled from "styled-components";
export const ListBoxStyle = styled.div`
  /* padding-top: var(--marginvw); */
  display: ${(props) => (props.noShow ? "none" : "flex")};

  .line-list {
    margin: 0.35vw var(--marginvw) 0.35vw var(--marginvw);
    border-bottom: 0.5px solid white;
    display: grid;
    grid-template-columns: 1vw 10vw 12vw 1fr 15vw 10vw 10vw;
    grid-gap: 1vw;
    width: 100%;
    align-items: center;
    :hover {
      border-bottom: 0.5px solid var(--blue);
    }
  }

  .line {
    display: flex;
  }

  .Zarrows {
    font-family: "ZennerArrows";
  }

  /* .line-list {
    width: 100%;
    display: flex;

    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid white;
    :hover {
      border-bottom: 0.5px solid var(--blue);
    }
  } */

  div {
    display: flex;
    height: 100%;
  }
  img {
    padding: var(--marginvw) 0;
    width: auto;
    height: 100%;
  }
  h4 {
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
    font-size: var(--h4) !important;
  }

  .small {
    width: 1.4vw !important;
    height: 1.4vw !important;
  }
  .ticket {
    text-align: right;
  }

  .hour {
    padding-left: var(--marginvw);
  }

  a {
    width: 99%;
    margin-bottom: 0 !important;
  }

  @media (max-width: 900px) {
    padding: 0.25rem 1rem;
    h3 {
      font-size: 0.64rem !important;
      padding-left: 0.2rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .title {
      flex-grow: 1;
    }

    .line-list {
      width: 100% !important;
      margin: 0;
      grid-template-columns: 5vw 17vw 38vw 31vw;
      grid-gap: 0.5vw;
    }

    .hour {
      display: none;
    }

    .line {
      justify-content: center;
    }

    .no-center {
      h3 {
        text-align: left;
      }
    }
    /* * {
      width: fit-content;
    } */
    /* .ais-SearchBox-reset {
      width: 66px;
      height: 66px;
      border: 0.5px solid;
    } */
    .small {
      width: 0.9rem !important;
      height: 0.9rem !important;
    }
  }
`;
