import React, { useEffect, useRef, useState } from "react";
import SanityBlockContent from "@sanity/block-content-to-react";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import { Link } from "gatsby";
import Image from "./Image";
import Ball from "./Ball";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ContentBoxStyle, LinkReadStyle } from "../styles/ContentBoxStyle";
import { useTranslation } from "react-i18next";
import { useGlobalData } from "../context/summerOrWinterContext";

function MyLinkRenderer(props) {
  return (
    <a href={props.mark.href} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  );
}

const serializers = {
  marks: {
    link: MyLinkRenderer,
  },
};
// import ClampLines from 'react-clamp-lines';

export default function ContentBox({
  elem,
  location,
  threeEventsOnly,
  numColumn,
  handleClick,
  mobile,
  hide,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const zeroDay = (MyDate) => {
    MyDate.setDate(MyDate.getDate() + 20);
    return `${`0${MyDate.getDate()}`.slice(-2)}`;
  };
  const zeroMonth = (MyDate) => {
    MyDate.setDate(MyDate.getDate() + 20);
    return `${`0${MyDate.getMonth() + 1}`.slice(-2)}`;
  };

  const dateMonth = zeroMonth(new Date(elem.eventDate));
  const dateDay = zeroDay(new Date(elem.eventDate));
  const dateHour = new Date(elem.eventDate).getUTCHours();
  const dateMinutes = new Date(elem.eventDate).getUTCMinutes();

  const { summerOrWinter } = useGlobalData();
  const [selectedManuscriptUrl, setSelectedManuscriptUrl] = useState(null);

  const handlePdfClick = (url, e) => {
    e.preventDefault(); // Prevent default link behavior
    setSelectedManuscriptUrl(url); // Set the URL for the embed
  };

  const handleClose = () => {
    setSelectedManuscriptUrl(null); // Clear the selected URL
  };

  return (
    <ContentBoxStyle place={elem.place} numColumn={numColumn} hide={hide}>
      <h3>{elem.title}</h3>

      <Slider {...settings}>
        {elem.images &&
          elem.images.map(
            (image) =>
              image.asset && (
                <div
                  onClick={(e) => (!mobile ? handleClick(e) : "")}
                  key={uuidv4()}
                >
                  <Image image={image} carousselType={elem.carousselType} />
                </div>
              )
          )}
      </Slider>

      <SanityBlockContent
        blocks={elem._rawText}
        projectId='2le2eemj'
        dataset='production'
        serializers={serializers}
        className='sanity-block'
      />

      {location === t("culinary") && (
        <Link
          className='button'
          to={
            i18n.language === "en"
              ? `/en/menu/${elem.place.toLowerCase()}`
              : `/menu/${elem.place.toLowerCase()}`
          }
        >
          <Ball
            summerOrWinter={summerOrWinter[0]?.summerOrWinter}
            size='small'
            place={elem.place}
          />
          <h4>
            {t("menu")} <span className='Zarrows'>➝</span>
          </h4>
        </Link>
      )}

      {elem.place === "Eisdiele" && (
        <a className='button' href='https://rosacanina.eu/' target='_blank'>
          <Ball
            summerOrWinter={summerOrWinter[0]?.summerOrWinter}
            size='small'
            place={elem.place}
          />
          <h4>
            {t("manufaktur")} <span className='Zarrows'>➝</span>
          </h4>
        </a>
      )}

      {((location === t("location") &&
        ["Saal", "Klub", "Biergarten", "Weingarten"].includes(elem.place)) ||
        threeEventsOnly) && (
        <>
          <Link
            className='button'
            to={
              i18n.language === "en"
                ? `/en/mietanfrage/${elem.place.toLowerCase()}`
                : `/mietanfrage/${elem.place.toLowerCase()}`
            }
          >
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={elem.place}
            />
            <h4>
              {t("mietanfrage")} <span className='Zarrows'>➝</span>
            </h4>
          </Link>
          <Link
            className='button'
            to={i18n.language === "en" ? `/en/programm` : `/programm`}
          >
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={elem.place}
            />
            <h4>
              {t("program")} <span className='Zarrows'>➝</span>
            </h4>
          </Link>
        </>
      )}

      {location === t("culinary") && elem.place !== "Eisdiele" && (
        <>
          <Link
            className='button'
            to={
              i18n.language === "en"
                ? `/en/catering/${elem.place.toLowerCase()}`
                : `/catering/${elem.place.toLowerCase()}`
            }
          >
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={elem.place}
            />
            <h4>
              {t("catering")} <span className='Zarrows'>➝</span>
            </h4>
          </Link>
        </>
      )}

      {location === t("rent") && (
        <>
          <Link
            className='button'
            to={
              i18n.language === "en"
                ? `/en/mietanfrage/${elem.place.toLowerCase()}`
                : `/mietanfrage/${elem.place.toLowerCase()}`
            }
          >
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={elem.place}
            />
            <h4>
              {t("mietanfrage")} <span className='Zarrows'>➝</span>
            </h4>
          </Link>

          <a
            className='button'
            href={elem.pdfUrl ? elem.pdfUrl : elem.manuscript.asset.url}
            // Optional: you might want to use the actual file name here
            download
            target='_blank' // Ensure it opens in a new tab/window
            rel='noopener noreferrer'
          >
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={elem.place}
            />
            <h4>
              {t("infos/capacities")} <span className='Zarrows'>➝</span>
            </h4>
          </a>
        </>
      )}

      <Ball
        summerOrWinter={summerOrWinter[0]?.summerOrWinter}
        place={elem.place}
      />
    </ContentBoxStyle>
  );
}
