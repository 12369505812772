import React, { useState, useEffect, useContext } from "react";
import { ContentColumnStyle } from "../styles/ContentColumnStyle";
import ContentBox from "./ContentBox";
import KulturContentBox from "./KulturContentBox";
import KulturList from "./KulturList";
import TopBarColumn from "./TopBarColumn";
import { useTranslation } from "react-i18next";
import { TitleContext } from "../context/titleContext";
import usePreviousLocation from "../utils/usePreviousLocation";

export default function ContentColumn(props) {
  const {
    place,
    data,
    notHidden,
    setBigScreen,
    bigScreen,
    setBigPlace,
    bigPlace,
    threeEventsOnly,
    showList,
    setShowList,
  } = props;

  const [overColumn, setOverColumn] = useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { locationPath, prevLocationPath } = usePreviousLocation();

  const { setTitle, title } = useContext(TitleContext);

  useEffect(() => {
    const titleFromStorage = localStorage.getItem("title");
    if (titleFromStorage && !title) {
      setTitle(titleFromStorage);
    }
  }, [setTitle, title]);

  useEffect(() => {
    if (
      title &&
      prevLocationPath &&
      (prevLocationPath.includes("/search") ||
        prevLocationPath.includes("/en/search"))
    ) {
      const element = document.getElementById(title);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [title, locationPath, prevLocationPath]);

  const handleClick =
    (showListFlag = false) =>
    (e) => {
      e.preventDefault();
      setBigScreen(!bigScreen);
      setBigPlace(place);
      if (showListFlag) setShowList(!showList);
    };

  const renderItem = (item, location) =>
    item.place === place && (
      <ContentBox
        elem={item}
        key={item.id}
        location={location}
        handleClick={handleClick()}
      />
    );

  const renderKulturContentBox = (item) =>
    item.place === place && (
      <KulturContentBox
        elem={item}
        key={item.id}
        threeEventsOnly={threeEventsOnly}
      />
    );

  const filteredNodes = (nodes) => nodes.filter((elem) => elem.place === place);

  const renderContent = () =>
    data && (
      <>
        {data.queryLocation &&
          data.queryLocation.nodes.map((item) =>
            renderItem(item, t("location"))
          )}
        {!threeEventsOnly &&
          data.queryKultur &&
          data.queryKultur.nodes.map(renderKulturContentBox)}
        {threeEventsOnly &&
          data.queryKultur &&
          filteredNodes(data.queryKultur.nodes)[0] && (
            <KulturContentBox
              elem={filteredNodes(data.queryKultur.nodes)[0]}
              threeEventsOnly={threeEventsOnly}
            />
          )}
        {data.queryKulinarik &&
          data.queryKulinarik.nodes.map((item) =>
            renderItem(item, t("culinary"))
          )}
        {data.queryMieten &&
          data.queryMieten.nodes.map((item) => renderItem(item, t("rent")))}
      </>
    );

  return (
    <ContentColumnStyle
      className='column'
      big={bigPlace === place && bigScreen}
      notHide={notHidden && !(bigScreen && bigPlace !== place)}
      place={place}
      onMouseOver={(e) => !bigScreen && setOverColumn(true)}
      onMouseOut={(e) => !bigScreen && setOverColumn(false)}
    >
      <TopBarColumn
        kultur={!threeEventsOnly}
        place={place}
        bigPlace={bigPlace}
        overColumn={overColumn}
        setOverColumn={setOverColumn}
        bigScreen={bigScreen}
        handleClick={handleClick(!threeEventsOnly)}
      />
      {!showList ? (
        renderContent()
      ) : (
        <KulturList data={data.queryKultur} place={place} />
      )}
    </ContentColumnStyle>
  );
}
