import React, { useRef } from "react";
import SanityBlockContent from "@sanity/block-content-to-react";
import { LinkReadStyle } from "../styles/ContentBoxStyle";

function MyLinkRenderer(props) {
  return (
    <a href={props.mark.href} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  );
}

const serializers = {
  marks: {
    link: MyLinkRenderer,
  },
};

export default function ReadMoreBox({
  readMore,
  elem,
  setReadMore,
  mobile,
  title,
}) {
  const contentRef = useRef(null);
  return (
    <LinkReadStyle readMore={!mobile ? readMore : true} ref={contentRef}>
      {elem._rawText && (
        <>
          {elem._rawText[1] ? (
            <SanityBlockContent
              className='read-more'
              blocks={elem._rawText}
              projectId='2le2eemj'
              dataset='production'
            />
          ) : (
            <div className='read-more'>
              <SanityBlockContent
                blocks={elem._rawText}
                projectId='2le2eemj'
                dataset='production'
                serializers={serializers}
              />
            </div>
          )}
        </>
      )}
      <a
        className='read-more-btn'
        href='#'
        onClick={(e) => {
          e.preventDefault();
          if (!mobile) setReadMore(!readMore);
          contentRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
      >
        {!mobile && <p>Read {readMore ? "less" : "more"}</p>}
      </a>
    </LinkReadStyle>
  );
}
