import styled from 'styled-components';

const CheckboxStyle = styled.div`
  padding-bottom: 1vw;
  h2 {
    text-transform: uppercase;
    padding-left: var(--marginvw);
  }
  h2.grey {
    color: var(--grey);
  }
  h2.blue {
    color: var(--blue);
  }
  display: flex;
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 20%;
    height: auto;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grey);

    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 0.5px solid var(--blue);
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 60%;
    left: 0%;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.5s;
    transition: 0.5s;

    border-radius: 100%;
  }

  input:checked + .slider {
    background-color: var(--blue);
  }

  /* input:focus + .slider {
    background-color: var(--grey);
  } */

  input:checked + .slider:before {
    -webkit-transform: translateX(66%);
    -ms-transform: translateX(66%);
    transform: translateX(66%);

    right: 3%;
    /* background-color: #1e2e53; */
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 89px;
    background-color: var(--grey);
  }

  @media (max-width: 900px) {

  }

`;

export default CheckboxStyle;
