// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-info-kontakt-js": () => import("./../../../src/pages/en/info-kontakt.js" /* webpackChunkName: "component---src-pages-en-info-kontakt-js" */),
  "component---src-pages-en-kulinarik-js": () => import("./../../../src/pages/en/kulinarik.js" /* webpackChunkName: "component---src-pages-en-kulinarik-js" */),
  "component---src-pages-en-location-js": () => import("./../../../src/pages/en/location.js" /* webpackChunkName: "component---src-pages-en-location-js" */),
  "component---src-pages-en-map-js": () => import("./../../../src/pages/en/map.js" /* webpackChunkName: "component---src-pages-en-map-js" */),
  "component---src-pages-en-mieten-js": () => import("./../../../src/pages/en/mieten.js" /* webpackChunkName: "component---src-pages-en-mieten-js" */),
  "component---src-pages-en-programm-js": () => import("./../../../src/pages/en/programm.js" /* webpackChunkName: "component---src-pages-en-programm-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-kontakt-js": () => import("./../../../src/pages/info-kontakt.js" /* webpackChunkName: "component---src-pages-info-kontakt-js" */),
  "component---src-pages-kulinarik-js": () => import("./../../../src/pages/kulinarik.js" /* webpackChunkName: "component---src-pages-kulinarik-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-mieten-js": () => import("./../../../src/pages/mieten.js" /* webpackChunkName: "component---src-pages-mieten-js" */),
  "component---src-pages-programm-js": () => import("./../../../src/pages/programm.js" /* webpackChunkName: "component---src-pages-programm-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-form-js": () => import("./../../../src/templates/Form.js" /* webpackChunkName: "component---src-templates-form-js" */),
  "component---src-templates-menu-js": () => import("./../../../src/templates/Menu.js" /* webpackChunkName: "component---src-templates-menu-js" */)
}

