import React from "react";
import { v4 as uuidv4 } from "uuid";
import ListBox from "./ListBox";

export default function KulturList({ data }) {
  return (
    <>
      {data &&
        data.nodes.map((elem, index) => {
          return <ListBox elem={elem} place={elem.place} key={uuidv4()} />;
        })}
    </>
  );
}
