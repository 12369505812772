import styled from "styled-components";

export const TopBarColumnStyle = styled.div`
  cursor: pointer;
  font-family: "ZennerArrows";
  height: 3vh;
  text-align: ${(props) => (props.big ? "end" : "center")};
  border-bottom: 0.5px solid var(--blue);
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: white;
  margin: 0px var(--marginvw);
  padding-bottom: var(--marginvw);
  display: ${(props) => (props.big ? "flex" : "block")};
  align-items: center;
  padding-top: 3px;

  h2 {
    flex-grow: ${(props) => (props.big ? "1" : "")};
    margin-right: ${(props) => (props.big ? "-7.4%" : "")};
    text-align: center;
  }

  .fa-1x {
    font-size: 0.75em;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: inherit;
  }
  .arrows-open {
    z-index: 1000;
    font-size: 1vw;
    /* margin-top: 10px; */
    padding-right: 1vw;
    padding-left: 1vw;
    border: 0.5px solid var(--blue);
    border-radius: 100px;
    padding-bottom: 2px;
    background-color: ${(props) => (props.big ? "var(--blue)" : "white")};
    background-color: ${(props) =>
      !props.overTop && props.big ? "white" : "var(--blue)"};
    background-color: ${(props) => {
      if (props.big && props.overTop) {
        return "white";
      }
      if (!props.big && props.overTop) {
        return "var(--blue)";
      }
      if (props.big && !props.overTop) {
        return "var(--blue)";
      }
      if (!props.big && !props.overTop) {
        return "white";
      }
    }};

    color: ${(props) => {
      if (props.big && props.overTop) {
        return "var(--blue)";
      }
      if (!props.big && props.overTop) {
        return "white";
      }
      if (props.big && !props.overTop) {
        return "white";
      }
      if (!props.big && !props.overTop) {
        return "var(--blue)";
      }
    }};

    /* :hover {
      background-color: ${(props) => (props.big ? "white" : "var(--blue)")};
      color: ${(props) => (props.big ? "var(--blue)" : "white")};
    } */
  }
`;
