import React from "react";
import styled from "styled-components";
import Ball from "./Ball";
import { useTranslation } from "react-i18next";
import { Link, navigate } from "gatsby";
import { useGlobalData } from "../context/summerOrWinterContext";

export const ListBtnStyle = styled.div`
  font-family: "ZennerArrows";

  .list-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #list {
    display: none;
  }
  #list:checked + .list-btn-container {
    background-color: var(--blue);
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
    color: white;
  }
`;

const TopBarMobil = ({ location, entry, setList, list, home }) => {
  const { t, i18n } = useTranslation();
  const { summerOrWinter } = useGlobalData();

  const CloseButton = ({ onClick, preventDefault }) => (
    <a
      className='close'
      onClick={(e) => {
        if (preventDefault) e.preventDefault();
        onClick();
      }}
    >
      ×
    </a>
  );

  const CateringLink = () => (
    <Link
      className='btn-cathering'
      to={i18n.language === "en" ? "/en/catering" : "/catering"}
    >
      <h3>{t("catering")}</h3>
    </Link>
  );

  const HeaderAndBall = () => (
    <>
      <h4>
        {location ? `${location} -` : ""}
        {entry !== "" &&
          ` ${
            entry === "Biergarten"
              ? summerOrWinter[0]?.summerOrWinter === "Summer"
                ? "Biergarten"
                : "Wintergarten"
              : entry
          }`}
      </h4>
      <Ball
        size='small'
        place={entry}
        summerOrWinter={summerOrWinter[0]?.summerOrWinter}
      />
    </>
  );

  return (
    <div className={home ? "hide" : "top-bar"}>
      {(location === t("culinary") || location === t("rent")) && (
        <CateringLink />
      )}

      {location === t("program") && !list && (
        <a
          className='btn-cathering events'
          href='#'
          onClick={(e) => {
            e.preventDefault(); // Prevent the default anchor action
            setList((prevList) => !prevList); // Correctly toggle based on previous state
          }}
        >
          <h3>{t("all events")}</h3>
        </a>
      )}

      {!list &&
        ![t("menu"), t("mietanfrage"), "Zenner Map", t("catering")].includes(
          location
        ) && <HeaderAndBall />}

      {list && (
        <>
          <h4>{t("program")}</h4>
          <CloseButton onClick={() => setList((prevList) => !prevList)} />
        </>
      )}

      {location === "Zenner Map" && (
        <>
          <h4>Zenner Map</h4>
          <CloseButton onClick={() => navigate(-1)} preventDefault />
        </>
      )}

      {[t("mietanfrage"), t("catering"), t("menu")].includes(location) && (
        <>
          <h4>
            {location ? `${location} -` : ""}
            {entry !== "" &&
              ` ${
                entry === "Biergarten"
                  ? summerOrWinter[0]?.summerOrWinter === "Summer"
                    ? "Biergarten"
                    : "Wintergarten"
                  : entry
              }`}
          </h4>

          <CloseButton onClick={() => navigate(-1)} preventDefault />
        </>
      )}
    </div>
  );
};

export default TopBarMobil;
