import styled from "styled-components";

export const HomeAnimStyle = styled.div`
  width: 100%;
  height: ${(props) =>
    props.newsOn
      ? "calc(100% - (var(--marginvw) * 3) - var(--h2))"
      : " calc(100% - (var(--marginvw)) )"};
  padding-top: ${(props) => (props.newsOn ? "var(--marginvw)" : "")};
  border-top: ${(props) => (props.newsOn ? "1px solid var(--blue)" : "")};

  .home {
    padding: var(--marginvw);
  }

  h2 {
    font-size: 5vw;
    letter-spacing: -0.1vw;
  }

  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */

  .imgBox {
    height: calc(80vh - var(--marginvw) * 6);
    width: calc(100% - 2rem);
    padding-left: var(--marginvw);
    display: flex;
    justify-content: space-between;

    .gatsby-image-wrapper {
      width: 80%;
    }
  }

  .titleBox {
    .ballBox {
      width: 15%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    display: flex;
    padding-left: var(--marginvw);
    /* height: 100%; */

    height: 7vh;
    align-items: flex-end;
    justify-content: space-between;
  }

  @media (max-width: 900px) {
    height: 100%;
    .top-bar {
      display: none !important;
    }

    .imgBox {
      /* width: calc(82vh - 2rem); */
      padding-top: 1rem;
      padding-left: var(--marginvw);
      padding-right: var(--marginvw);

      .gatsby-image-wrapper {
        width: 100%;
        height: auto;
      }
    }

    h2 {
      font-size: 10vw;
    }

    .titleBox {
      margin-top: 5vh;
      height: ${(props) => (props.newsOn ? "24vh" : " 31vh")};
      .ballBox {
        width: 20%;
        margin-right: var(--marginvw);
      }
    }
    .imgBox {
      height: 60vw;
    }
  }
`;
