import React from "react";
import { GridStyle } from "../styles/GridStyle";
import CheckboxColumn from "./CheckboxColumn";
import HomeAnim from "./HomeAnim";
import FormComponent from "./FormComponent";
import MenuComponent from "./MenuComponent";
import MapComponent from "./MapComponent";
import SearchColumnBox from "./SearchColumnBox";
import InfoColumnBox from "./InfoColumnBox";
import PlacesColumnBox from "./PlacesColumnBox";
import { useTranslation } from "react-i18next";

const BoxColumnSelector = (location, pageContent, pageContext, language) => {
  const path = location.pathname;
  if (/search/.test(path)) return <SearchColumnBox language={language} />;
  if (/catering/.test(path) || /mietanfrage/.test(path))
    return <FormComponent data={pageContent} pageContext={pageContext} />;
  if (/menu/.test(path))
    return <MenuComponent data={pageContent} location={location} />;
  if (
    path === "/map" ||
    path === "/map/" ||
    path === "/en/map" ||
    path === "/en/map/"
  )
    return <MapComponent data={pageContent} location={location} />;
  if (/info-kontakt/.test(path)) return <InfoColumnBox data={pageContent} />;
  if (path === "/" || path === "/en" || path === "/en/")
    return <HomeAnim data={pageContent} />;
  return <PlacesColumnBox data={pageContent} location={location} />;
};

export default function Grid({ pageContent, location, pageContext }) {
  const { i18n } = useTranslation();

  return (
    <GridStyle>
      <CheckboxColumn location={location} />
      {BoxColumnSelector(location, pageContent, pageContext, i18n.language)}
    </GridStyle>
  );
}
