import styled from "styled-components";

export const InfoColumnStyle = styled.div`
  display: ${(props) => (props.notHide ? "block" : "none")};
  width: 100%;
  border-left: 0.5px solid var(--blue);
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  .Zarrows {
    font-family: "ZennerArrows";
  }

  p,
  a,
  select,
  input,
  textarea {
    font-size: ${(props) => (props.big ? "1.5vw " : "")};
  }

  h4 {
    font-size: ${(props) => (props.big ? "1.4vw " : "")};
  }

  h5 {
    font-size: ${(props) => (props.big ? "1.4vw " : "")};
  }
  h3 {
    font-size: ${(props) => (props.big ? "3vw " : "")};
  }

  .small {
    height: ${(props) => (props.big ? "1.4vw " : "")};
    width: ${(props) => (props.big ? "1.4vw " : "")};
  }

  /* color: ${(props) => (props.big ? "blue" : "red")}; */

  ul {
    padding-top: var(--marginvw);

    :first-child {
      border-left: 0;
      border-bottom: 1px solid var(--blue);
    }
  }

  .gatsby-image-wrapper.portrait {
    height: ${(props) => (props.big ? "calc(100vh - 3vh - 3rem)" : "13vw")};
    object-fit: contain;
    object-position: center;
  }

  .gatsby-image-wrapper.landscape {
    height: ${(props) => (props.big ? "calc(100vh - 3vh - 3rem)" : "20vw")};
    object-fit: contain;
    object-position: center;
  }

  .slick-arrow {
    /* display: ${(props) =>
      props.overColumn || props.big ? "block" : "none"} !important; */
    margin-top: -1.5vh;
  }
`;
