import styled from "styled-components";

export const LinkReadStyle = styled.div`
  .Zarrows {
    font-family: "ZennerArrows";
  }

  .read-more-btn {
    p {
      padding: var(--marginvw) 0 0.5vw 0;
      margin: 0;
      font-family: "fontMed";
    }
  }
  .read-more {
    height: ${(props) => (props.readMore ? "" : "3.1vw")};
    overflow: hidden;
    p {
      display: ${(props) => (props.readMore ? "block" : "-webkit-box")};
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  @media (max-width: 900px) {
    .read-more {
      height: ${(props) => (props.readMore ? "" : "16.1vw")};
    }
  }
`;

export const ContentBoxStyle = styled.div`
  /* margin-top: var(--marginvw); */
  .pdf-viewer {
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;

      z-index: 50000000;
    }
    .close {
      position: absolute;
      z-index: 50000001;
      top: 61px;
      right: 5vw;
      background-color: white;
      border: 0.5px solid var(--blue);
      border-radius: 50%;
      font-size: 3.35vw;
      width: 3.5vw;
      height: 3.5vw;
      line-height: 0%;
      padding-bottom: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      :hover {
        background-color: var(--blue);
        color: white;
      }
    }
  }
  padding: var(--marginvw);
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  font-family: "fontReg";

  .Zarrows {
    font-family: "ZennerArrows";
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    margin-bottom: var(--marginvw);
  }

  textarea {
    width: 100%;
  }
  .list-ball {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2vh;
    width: 100%;
    .button {
      width: auto;
    }
    img {
      width: auto !important;
      height: 100%;
      padding-top: 0vw;
    }
  }
  p {
    margin-bottom: calc(var(--p) / 2.5);
    width: 100%;
  }

  p > a:hover {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: underline;
  }
  em > a:hover {
    text-decoration: underline;
  }
  h3.align-left {
    text-align: left;
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 0.25vw;
    text-transform: uppercase;
    h4 {
      padding-left: 0.5vw;
    }

    :hover {
      text-decoration: underline;
    }
  }

  //KULTUR

  .event {
    width: 100%;
  }

  .event-info {
    margin: 0 0 0.5vw;
    display: flex;
    justify-content: space-between;
  }

  .event-name {
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
    font-family: "fontReg";
    margin-bottom: 0.5vw;
  }

  // INFO
  .info-box {
    width: 100%;
    margin-top: var(--marginvw);
  }

  form {
    width: 100%;
    input,
    select {
      width: 100%;
      margin-bottom: 0.5vw;
      border: 0;
      border-bottom: 0.5px solid var(--blue);
      background-color: white;
    }
    h3 {
      margin-top: 3vw;
    }
    .senden {
      width: auto;
      margin: 0 auto 1vw auto;
      display: block;
      padding-right: var(--marginvw);
      padding-left: var(--marginvw);
      border: 0.5px solid var(--blue);
      border-radius: 100px;
      background-color: white;
      cursor: pointer;
      &:hover {
        background-color: var(--blue);
        color: white;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  fieldset {
    border: 0;
    padding: 0.5vw 0 0.5vw 0;
  }
  .form-info {
    padding-bottom: calc(var(--marginvw) * 2);
  }

  @media (max-width: 900px) {
    .event-name {
      margin: 0 !important;
    }
    p {
      margin-bottom: 2.3vw;
    }
    .form-info {
      padding-bottom: 2rem;
    }
    h3 {
      padding-bottom: 1rem;
      text-align: center;
    }
    .gatsby-image-wrapper.landscape {
      height: 61vw;
      object-fit: contain;
      object-position: center;
    }

    .gatsby-image-wrapper.portrait {
      height: 121vw;
      object-fit: contain;
      object-position: center;
    }

    .button {
      padding-top: 0 !important;
      margin-bottom: 1.3vw;
      h4 {
        padding-left: 1.5vw;
      }
    }

    margin-bottom: var(--marginvw);
  }
`;
