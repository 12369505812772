import React, { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
import { GridMobilStyle } from "../styles/GridMobilStyle";
import ContentBox from "./ContentBox";
import ListBox from "./ListBox";
import KulturContentBox from "./KulturContentBox";
import MenuMobil from "./MenuMobil";
import TopBarMobil from "./TopBarMobil";
import InfoBox from "./InfoBox";
import ListSearchMobil from "./ListSearchMobil";
import HomeAnim from "./HomeAnim";
import MenuComponent from "./MenuComponent";
import MapComponent from "./MapComponent";
import FormComponent from "./FormComponent";
import { useTranslation } from "react-i18next";

const searchClient = algoliasearch(
  "WJ533DXBBS",
  "130fecb5438ace336edc7e1f64c9104d"
);

export default function GridMobil({ pageContent, location, pageContext }) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [entry, setEntry] = useState("");
  const [locationAll, setLocationAll] = useState();
  const [list, setList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    saalShow();
    setupSliderOpacity();
  }, [location, searchQuery, i18n.language]);

  useEffect(() => {
    setSearchQuery(""); // Clear the search when language changes
  }, [i18n.language]);

  useEffect(() => {
    setFilteredData(filterData()); // Update filtered data when dependencies change
  }, [data, location.pathname, i18n.language, pageContent, locationAll]);

  const saalShow = () => {
    const path = location.pathname;
    const setContent = (queryKey, locationKey) => {
      setData(pageContent[queryKey] && pageContent[queryKey].nodes);
      setLocationAll(t(locationKey));
      setList(false);
    };

    if (/location/.test(path) || /\/en\/location/.test(path))
      setContent("queryLocation", "location");
    else if (/programm/.test(path) || /\/en\/programm/.test(path))
      setContent("queryKultur", "program");
    else if (/kulinarik/.test(path) || /\/en\/kulinarik/.test(path))
      setContent("queryKulinarik", "culinary");
    else if (/mieten/.test(path) || /\/en\/mieten/.test(path))
      setContent("queryMieten", "rent");
    else if (/info-kontakt/.test(path) || /\/en\/info-kontakt/.test(path)) {
      setData([]);
      setLocationAll();
      setList(false);
      setEntry("About");
    } else if (/menu/.test(path) || /\/en\/menu/.test(path)) {
      setData([]);
      setLocationAll(t("menu"));
      setEntry(pageContent.menu.place);
      setList(false);
    } else if (/map/.test(path) || /\/en\/map/.test(path)) {
      setData([]);
      setLocationAll("Zenner Map");
      setEntry("Zenner Map");
      setList(false);
    } else if (
      /catering|mietanfrage/.test(path) ||
      /\/en\/catering|\/en\/mietanfrage/.test(path)
    ) {
      setData([]);
      setLocationAll(
        pageContext.name === "Catering" ? t("catering") : t("mietanfrage")
      );
      setEntry(pageContext.place);
      setList(false);
    } else if (path === "/" || path === "/en/") {
      setData([]);
      setLocationAll();
      setEntry("");
      setList(false);
    }
  };

  const setupSliderOpacity = () => {
    document.querySelectorAll(".slick-slider").forEach((elem) => {
      const left = elem.querySelector(".slick-next");
      const right = elem.querySelector(".slick-prev");
      if (left && right) {
        elem.addEventListener("mouseover", () => {
          left.style.opacity = "1";
          right.style.opacity = "1";
        });
        elem.addEventListener("mouseout", () => {
          left.style.opacity = "0";
          right.style.opacity = "0";
        });
      }
    });
  };

  const filterData = () => {
    let newData = [];

    if (data && !/search/.test(location.pathname)) {
      data.forEach((elem) => {
        if (elem.place === "Saal") {
          if (
            locationAll === "PROGRAMM" &&
            !pageContent.queryShowHidePlaces?.nodes[0]?.saalShow
          ) {
            return;
          } else {
            newData.push(elem);
          }
        }
      });
      data.forEach((elem) => {
        if (elem.place === "Klub") {
          const showProgramm =
            pageContent.queryShowHidePlaces?.nodes[0]?.klubShowProgramm;
          const showLocation =
            pageContent.queryShowHidePlaces?.nodes[0]?.klubShowLocation;
          const showMieten =
            pageContent.queryShowHidePlaces?.nodes[0]?.klubShowMieten;

          if (
            ((locationAll === "PROGRAMM" || locationAll === "PROGRAM") &&
              !showProgramm) ||
            (locationAll === "LOCATION" && !showLocation) ||
            ((locationAll === "MIETEN" || locationAll === "RENT") &&
              !showMieten)
          ) {
            return;
          }

          newData.push(elem);
        }
      });
      data.forEach((elem) => {
        if (elem.place === "Biergarten") {
          const showKulinarik =
            pageContent.queryShowHidePlacesKulinarik?.nodes[0]
              ?.biergartenShowKulinarik;
          const showProgramm =
            pageContent.queryShowHidePlaces?.nodes[0]?.biergartenShow;

          if (
            ((locationAll === "KULINARIK" || locationAll === "CULINARY") &&
              !showKulinarik) ||
            ((locationAll === "PROGRAMM" || locationAll === "PROGRAM") &&
              !showProgramm)
          ) {
            return;
          }

          newData.push(elem);
        }
      });

      data.forEach((elem) => {
        if (elem.place === "Weingarten") {
          const showKulinarik =
            pageContent.queryShowHidePlacesKulinarik?.nodes[0]
              ?.wiengartenShowKulinarik;
          const showProgramm =
            pageContent.queryShowHidePlaces?.nodes[0]?.wiengartenShow;

          if (
            ((locationAll === "KULINARIK" || locationAll === "CULINARY") &&
              !showKulinarik) ||
            ((locationAll === "PROGRAMM" || locationAll === "PROGRAM") &&
              !showProgramm)
          ) {
            return;
          }

          newData.push(elem);
        }
      });

      data.forEach((elem) => {
        if (elem.place === "Eisdiele") {
          newData.push(elem);
        }
      });
      data.forEach((elem) => {
        if (elem.place === "Körnervilla") {
          newData.push(elem);
        }
      });
      data.forEach((elem) => {
        if (
          elem.place === "Pop-Up Bistro" &&
          pageContent.queryShowHidePlacesKulinarik?.nodes[0]?.PopupShowKulinarik
        ) {
          newData.push(elem);
        }
      });
      data.forEach((elem) => {
        if (elem.place === "Turmhaus") {
          newData.push(elem);
        }
      });
    }
    return newData;
  };

  return (
    <GridMobilStyle place={entry}>
      <MenuMobil location={location} />

      {/search/.test(location.pathname) ? (
        <InstantSearch
          searchClient={searchClient}
          indexName='zenner'
          onSearchStateChange={(searchState) => {
            setSearchQuery(searchState.query);
          }}
        >
          <SearchBox />
          {searchQuery && <Hits hitComponent={ListSearchMobil} />}
        </InstantSearch>
      ) : (
        <TopBarMobil
          entry={entry}
          list={list}
          setList={setList}
          home={
            location.pathname === "/" ||
            location.pathname === "/en/" ||
            location.pathname === "/en"
          }
          location={locationAll}
        />
      )}

      {filteredData &&
        !/search/.test(location.pathname) &&
        filteredData.map((elem) => (
          <InView
            as='div'
            threshold={0.5}
            onChange={(inView) => inView && setEntry(elem.place)}
            key={elem.id}
          >
            {list && locationAll === t("program") && (
              <ListBox elem={elem} place={elem.place} mobile={true} />
            )}
            {!list && locationAll === t("program") && (
              <KulturContentBox elem={elem} mobile={true} />
            )}
            {locationAll !== t("program") && (
              <ContentBox mobile={true} elem={elem} location={locationAll} />
            )}
          </InView>
        ))}

      {/info-kontakt/.test(location.pathname) && (
        <>
          <InView
            as='div'
            threshold={0}
            // onChange={(inView) => inView && setEntry('About')}
            onChange={(inView) => (inView ? setEntry("About") : "no")}
          >
            <InfoBox
              elem={pageContent.queryAbout?.nodes[0]}
              location='About'
              mobile={true}
            />
          </InView>
          <InView
            as='div'
            threshold={0}
            onChange={(inView) => inView && setEntry(t("info / contact"))}
          >
            <InfoBox
              elem={pageContent.queryKontakt?.nodes[0]}
              location={t("info / contact")}
              mobile={true}
            />
          </InView>
          <InView
            as='div'
            threshold={0}
            onChange={(inView) => inView && setEntry("Historie")}
          >
            <InfoBox
              elem={pageContent.queryHistory?.nodes[0]}
              location='Historie'
              mobile={true}
            />
          </InView>
        </>
      )}

      {/menu/.test(location.pathname) && (
        <MenuComponent data={pageContent} location={location} />
      )}
      {(location.pathname === "/map" || location.pathname === "/map/") && (
        <MapComponent data={pageContent} location={location} />
      )}

      {(/catering/.test(location.pathname) ||
        /mietanfrage/.test(location.pathname)) && (
        <FormComponent data={pageContent} pageContext={pageContext} />
      )}
      {(location.pathname === "/" ||
        location.pathname === "/en" ||
        location.pathname === "/en/") && (
        <HomeAnim mobile={true} data={pageContent} />
      )}
    </GridMobilStyle>
  );
}
