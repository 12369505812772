import React, { useEffect, useState } from "react";

import { Highlight } from "react-instantsearch-dom";
import { ListBoxStyle } from "../styles/ListBoxStyle";
import Ball from "./Ball";
import { useTranslation } from "react-i18next";
import { useGlobalData } from "../context/summerOrWinterContext";

export default function ListSearchMobil({ hit }) {
  const { t, i18n } = useTranslation();

  if (hit.language !== i18n.language) {
    return null;
  }

  const addZero = (i) => (i < 10 ? `0${i}` : i);

  let dateMonth, dateDay, dateHour, dateMinutes, eventDate;

  if (hit.eventDate) {
    eventDate = new Date(hit.eventDate);
    dateMonth = addZero(eventDate.getMonth() + 1);
    dateDay = addZero(eventDate.getDate());
    dateHour = addZero(eventDate.getHours());
    dateMinutes = addZero(eventDate.getMinutes());
  }

  const noShow = () => {
    const currentDate = new Date();
    const diffTime = currentDate - eventDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays >= 2;
  };
  const { summerOrWinter } = useGlobalData();
  return (
    <ListBoxStyle noShow={noShow()}>
      {hit.typeOfEvent && (
        <a href={hit.linkEvent} target='_blank'>
          <div className='line-list'>
            {" "}
            <Ball
              summerOrWinter={summerOrWinter[0]?.summerOrWinter}
              size='small'
              place={hit.place}
            />
            <div className='date-event'>
              <h4>
                {dateDay}/{dateMonth}
              </h4>
            </div>
            <h4>
              <Highlight hit={hit} attribute='title' tagName='mark' />
            </h4>
            <h4 className='ticket'>
              Ticket <span className='Zarrows'>➝</span>
            </h4>
          </div>
        </a>
      )}
      {!hit.typeOfEvent &&
        (hit.place === "Biergarten" || hit.place === "Weingarten") && (
          <a href={`/menu/${hit.place.toLowerCase()}`}>
            <div className='line-list'>
              <Ball
                summerOrWinter={summerOrWinter[0]?.summerOrWinter}
                size='small'
                place={hit.place}
              />

              <h4></h4>

              <h4>
                <Highlight hit={hit} attribute='title' tagName='mark' />
              </h4>

              <h4 className='ticket'>
                {t("menu")} <span className='Zarrows'>➝</span>
              </h4>
            </div>
          </a>
        )}
    </ListBoxStyle>
  );
}
