import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import Checkbox from "./CheckBox";
import { CheckboxColumnStyle } from "../styles/CheckboxColumnStyle";
import logoZenner from "../assets/zenner-elements/zenner-logo.png";
import { useTranslation } from "react-i18next";

const CheckboxColumn = ({ location }) => {
  const { t, i18n } = useTranslation();

  const [checkboxes, setCheckboxes] = useState({
    location: false,
    programm: false,
    kulinarik: false,
    mieten: false,
  });

  const handleCheckboxChange = (checkbox) => {
    const newCheckboxes = { ...checkboxes };
    const isChecked = newCheckboxes[checkbox];

    // Close the checkbox if it's already open
    if (isChecked) {
      newCheckboxes[checkbox] = false;
      navigateWithLocale("/");
    } else {
      // Close other checkboxes and open the selected checkbox
      Object.keys(newCheckboxes).forEach((key) => {
        newCheckboxes[key] = false;
      });
      newCheckboxes[checkbox] = true;
      navigateWithLocale(`/${checkbox}`);
    }

    setCheckboxes(newCheckboxes);
  };

  const navigateWithLocale = (path) => {
    let newPath =
      path.startsWith("/en") || i18n.language !== "en" ? path : `/en${path}`;
    navigate(newPath);
  };

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/info-kontakt" ||
      location.pathname === "/info-kontakt/" ||
      location.pathname === "/en/info-kontakt" ||
      location.pathname === "/en/info-kontakt/" ||
      location.pathname === "/search" ||
      location.pathname === "/search/"
    ) {
      setCheckboxes({
        location: false,
        programm: false,
        kulinarik: false,
        mieten: false,
      });
    } else if (
      location.pathname === "/location" ||
      location.pathname === "/en/location" ||
      location.pathname === "/location/" ||
      location.pathname === "/en/location/"
    ) {
      setCheckboxes({
        location: true,
        programm: false,
        kulinarik: false,
        mieten: false,
      });
    } else if (
      location.pathname === "/kulinarik" ||
      location.pathname === "/kulinarik/" ||
      location.pathname === "/en/kulinarik" ||
      location.pathname === "/en/kulinarik/" ||
      /menu/.test(location.pathname)
    ) {
      setCheckboxes({
        location: false,
        programm: false,
        kulinarik: true,
        mieten: false,
      });
    } else if (
      location.pathname === "/programm" ||
      location.pathname === "/en/programm" ||
      location.pathname === "/programm/" ||
      location.pathname === "/en/programm/"
    ) {
      setCheckboxes({
        location: false,
        programm: true,
        kulinarik: false,
        mieten: false,
      });
    } else if (
      location.pathname === "/mieten" ||
      location.pathname === "/mieten/" ||
      location.pathname === "/en/mieten" ||
      location.pathname === "/en/mieten/"
    ) {
      setCheckboxes({
        location: false,
        programm: false,
        kulinarik: false,
        mieten: true,
      });
    }
  }, [location]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    let currentPath = location.pathname;
    let newPath =
      currentPath.startsWith("/en") && lng !== "en"
        ? currentPath.slice(3)
        : currentPath;
    navigateWithLocale(newPath);
  };

  return (
    <CheckboxColumnStyle>
      <div>
        <Link to={i18n.language === "en" ? "/en/" : "/"}>
          <img
            className='logo'
            src={logoZenner}
            alt=''
            width='100%'
            height='auto'
          />
        </Link>
        <Checkbox
          setCheck={() => handleCheckboxChange("location")}
          status={checkboxes.location}
          title={t("location")}
        />
        <Checkbox
          setCheck={() => handleCheckboxChange("programm")}
          status={checkboxes.programm}
          title={t("program")}
        />
        <Checkbox
          setCheck={() => handleCheckboxChange("kulinarik")}
          status={checkboxes.kulinarik}
          title={t("culinary")}
        />
        <Checkbox
          setCheck={() => handleCheckboxChange("mieten")}
          status={checkboxes.mieten}
          title={t("rent")}
        />
        {/* <Link to={i18n.language === "en" ? "/en/" : "/"}>
          <p className='clear-filters'>{t("clear filters")}</p>
        </Link> */}
      </div>

      <div className='footer'>
        <div className='info-kontakt'>
          <Link to={i18n.language === "en" ? "/en/search" : "/search"}>
            <p>{t("search")}</p>
          </Link>
          <Link
            to={i18n.language === "en" ? "/en/info-kontakt" : "/info-kontakt"}
          >
            <p>{t("info / contact")}</p>
          </Link>
          <Link
            to={i18n.language === "en" ? `/en/info-kontakt` : "/info-kontakt"}
          >
            <p>{t("imprint")}</p>
          </Link>
        </div>
        <div className='socialLinks'>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault();
              changeLanguage("de");
            }}
            target='_blank'
            className={i18n.language !== "de" ? "active" : ""}
          >
            <p>DE</p>
          </a>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault();
              changeLanguage("en");
            }}
            target='_blank'
            className={i18n.language !== "en" ? "active" : ""}
          >
            <p>EN</p>
          </a>
        </div>
      </div>
    </CheckboxColumnStyle>
  );
};

export default CheckboxColumn;
