import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { PlacesColumnBoxStyle } from "../styles/PlacesColumnBoxStyle";
import ContentColumn from "./ContentColumn";
import { useTranslation } from "react-i18next";

export default function PlacesColumnBox({ data, location }) {
  const [bigScreen, setBigScreen] = useState(false);
  const [bigPlace, setBigPlace] = useState("");
  const [threeEventsOnly, setThreeEventsOnly] = useState(false);
  const [showList, setShowList] = useState(false);
  const [numColumn, setNumColumn] = useState(0);
  const [locationPath, setLocationPath] = useState(false);
  const [kulturPath, setKulturPath] = useState(false);
  const [kulinarikPath, setKulinarikPath] = useState(false);
  const [mietenPath, setMietenPath] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const columns = () => {
    if (locationPath) {
      let count = 6;
      const showStates = data?.queryShowHidePlaces?.nodes[0];
      if (showStates?.klubShowLocation) count++;

      setNumColumn(count);
    } else if (kulinarikPath && (kulturPath || mietenPath)) {
      setNumColumn(5);
    } else if (kulturPath) {
      let count = 0;
      const showStates = data?.queryShowHidePlaces?.nodes[0];
      if (showStates?.saalShow) count++;
      if (showStates?.biergartenShow) count++;
      if (showStates?.klubShowProgramm) count++;
      if (showStates?.wiengartenShow) count++;
      setNumColumn(count);
    } else if (kulinarikPath) {
      // setNumColumn(3);

      let count = 1;
      const showStates = data?.queryShowHidePlacesKulinarik?.nodes[0];
      if (showStates?.biergartenShowKulinarik) count++;
      if (showStates?.wiengartenShowKulinarik) count++;
      if (showStates?.PopupShowKulinarik) count++;
      setNumColumn(count);
    } else if (mietenPath) {
      let count = 3;
      const showStates = data?.queryShowHidePlaces?.nodes[0];
      if (showStates?.klubShowMieten) count++;
      setNumColumn(count);
    } else {
      setNumColumn(0);
    }
  };

  const saalShow = () => {
    if (/location/.test(location.pathname)) {
      setLocationPath(true);
    } else {
      setLocationPath(false);
    }
    if (/programm/.test(location.pathname)) {
      setKulturPath(true);
    } else {
      setKulturPath(false);
    }
    if (/kulinarik/.test(location.pathname) || /menu/.test(location.pathname)) {
      setKulinarikPath(true);
    } else {
      setKulinarikPath(false);
    }
    if (/mieten/.test(location.pathname)) {
      setMietenPath(true);
    } else {
      setMietenPath(false);
    }
  };

  useEffect(() => {
    setBigScreen(false);
    setShowList(false);
    saalShow();

    setThreeEventsOnly(
      location.pathname !== "/programm" &&
        location.pathname !== "/programm/" &&
        location.pathname !== "/en/programm" &&
        location.pathname !== "/en/programm/"
    );
  }, [location]);

  useEffect(() => {
    columns();
  }, [locationPath, kulinarikPath, kulturPath, mietenPath, bigScreen]);

  return (
    <PlacesColumnBoxStyle
      numColumn={numColumn}
      big={bigScreen}
      className='contentBox'
    >
      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        showList={showList}
        setShowList={setShowList}
        place='Saal'
        data={data}
        setBigPlace={setBigPlace}
        threeEventsOnly={threeEventsOnly}
        notHidden={
          locationPath ||
          (kulturPath && data?.queryShowHidePlaces?.nodes[0]?.saalShow) ||
          mietenPath
        }
      />
      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        setShowList={setShowList}
        bigPlace={bigPlace}
        showList={showList}
        place='Klub'
        data={data}
        setBigPlace={setBigPlace}
        threeEventsOnly={threeEventsOnly}
        notHidden={
          (locationPath &&
            data?.queryShowHidePlaces?.nodes[0]?.klubShowLocation) ||
          (mietenPath && data?.queryShowHidePlaces?.nodes[0]?.klubShowMieten) ||
          (kulturPath && data?.queryShowHidePlaces?.nodes[0]?.klubShowProgramm)
        }
      />

      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        setShowList={setShowList}
        bigPlace={bigPlace}
        showList={showList}
        place='Biergarten'
        data={data}
        threeEventsOnly={threeEventsOnly}
        setBigPlace={setBigPlace}
        notHidden={
          locationPath ||
          (kulturPath && data?.queryShowHidePlaces?.nodes[0]?.biergartenShow) ||
          (kulinarikPath &&
            data?.queryShowHidePlacesKulinarik?.nodes[0]
              ?.biergartenShowKulinarik) ||
          mietenPath
        }
      />
      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        showList={showList}
        setShowList={setShowList}
        threeEventsOnly={threeEventsOnly}
        place='Weingarten'
        data={data}
        setBigPlace={setBigPlace}
        notHidden={
          locationPath ||
          (kulturPath && data?.queryShowHidePlaces?.nodes[0]?.wiengartenShow) ||
          (kulinarikPath &&
            data?.queryShowHidePlacesKulinarik?.nodes[0]
              ?.wiengartenShowKulinarik) ||
          mietenPath
        }
      />
      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        showList={showList}
        setShowList={setShowList}
        threeEventsOnly={threeEventsOnly}
        place='Pop-Up Bistro'
        data={data}
        setBigPlace={setBigPlace}
        notHidden={
          kulinarikPath &&
          data?.queryShowHidePlacesKulinarik?.nodes[0]?.PopupShowKulinarik
        }
      />
      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        showList={showList}
        setShowList={setShowList}
        threeEventsOnly={threeEventsOnly}
        place='Eisdiele'
        data={data}
        setBigPlace={setBigPlace}
        notHidden={locationPath || kulinarikPath}
      />
      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        showList={showList}
        setShowList={setShowList}
        threeEventsOnly={threeEventsOnly}
        place='Körnervilla'
        data={data}
        setBigPlace={setBigPlace}
        notHidden={locationPath}
      />
      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        showList={showList}
        setShowList={setShowList}
        threeEventsOnly={threeEventsOnly}
        place='Turmhaus'
        data={data}
        setBigPlace={setBigPlace}
        notHidden={locationPath}
      />
      {(location.pathname === "/kulinarik" ||
        location.pathname === "/en/kulinarik" ||
        location.pathname === "/en/kulinarik/" ||
        location.pathname === "/kulinarik/" ||
        location.pathname === "/mieten" ||
        location.pathname === "/en/mieten" ||
        location.pathname === "/mieten" ||
        location.pathname === "/en/mieten/") && (
        <Link
          className='btn-cathering'
          to={i18n.language === "en" ? "/en/catering" : "/catering"}
        >
          <h3>catering </h3>
        </Link>
      )}
      {/* { bigScreen &&      <ContentColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        showList={showList}
        setShowList={setShowList}
        threeEventsOnly={threeEventsOnly}
        place={bigPlace}
        data={data}
        setBigPlace={setBigPlace}
        notHidden={locationPath}
      /> }
     {showList && <BigColumn bigPlace={bigPlace}/>} */}
    </PlacesColumnBoxStyle>
  );
}
