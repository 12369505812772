import styled from "styled-components";

export const ContentColumnStyle = styled.div`
  display: ${(props) => (props.notHide ? "block" : "none")};
  scroll-behavior: smooth;

  ul {
    padding-top: var(--marginvw);

    :first-child {
      border-left: 0;
      border-bottom: 1px solid var(--blue);
    }
  }

  .portrait {
    object-fit: contain;
    object-position: center;
  }

  .landscape {
    object-fit: contain;
    object-position: center;
  }
`;
