import styled from "styled-components";

export const CheckboxColumnStyle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - (var(--marginvw) * 2));

  .logo {
    margin-bottom: 2vw;
    width: 80%;
  }

  h4 {
    margin-bottom: var(--marginvw);
  }

  .clear-filters {
    margin-top: 1.5vw;
  }

  .info-kontakt {
    margin-bottom: 2vw;
  }

  .info-kontakt {
    p {
      padding-top: 0.5vw;
    }
  }

  .socialLinks {
    margin-top: var(--marginvw);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(80px + var(--marginvw));
    a {
      h4 {
        margin-bottom: 0;
      }
      background-color: var(--blue);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 10px; */
      height: 40px;
      width: 40px;
      text-transform: uppercase;
      border-radius: 50%;
      text-align: center;
    }
  }

  .socialLinks {
    a.active {
      background-color: white;
      border: 1px solid var(--blue);
      color: var(--blue);
    }
  }

  @media (max-width: 900px) {
  }
`;
