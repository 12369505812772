import React, { useState, useEffect } from "react";
import "normalize.css";

import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
import Grid from "./Grid";
import GridMobil from "./GridMobil";
import SEO from "./SEO";
import { TitleContext } from "../context/titleContext";

export default function Layout({ children, location, pageContext }) {
  const [title, setTitle] = useState("");
  const [isMobile, setIsMobile] = useState(false); // Default to false

  useEffect(() => {
    // Function to determine if the current view is mobile
    function checkIfMobile() {
      return window.innerWidth <= 900; // Example breakpoint
    }

    // Initial check on the client side
    setIsMobile(checkIfMobile());

    function handleResize() {
      setIsMobile(checkIfMobile());
    }

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      <SEO
        description={children.props.data?.seo?.nodes[0]?.description}
        image={children.props.data?.seo?.nodes[0]?.image?.asset?.fluid?.src}
        title={children.props.data?.seo?.nodes[0]?.title}
        titlePage={children.props.data?.seo?.nodes[0]?.titlePage}
      />

      <GlobalStyles />
      <Typography />
      {isMobile ? (
        <GridMobil
          pageContent={children.props.data}
          location={location}
          pageContext={pageContext}
        />
      ) : (
        <Grid
          pageContent={children.props.data}
          location={location}
          pageContext={pageContext}
        />
      )}
    </TitleContext.Provider>
  );
}
