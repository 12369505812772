import React, { useEffect, useState } from "react";
import bio from "../assets/zenner-elements/bio 2.png";
import vegan from "../assets/zenner-elements/vegan.png";
import vege from "../assets/zenner-elements/vegetarisch.png";
import { v4 as uuidv4 } from "uuid";

import { navigate } from "gatsby";
import { MenuStyle } from "../styles/MenuStyle";
import SanityBlockContent from "@sanity/block-content-to-react";
import { useTranslation } from "react-i18next";

function MyLinkRenderer(props) {
  return (
    <a href={props.mark.href} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  );
}

const serializers = {
  marks: {
    link: MyLinkRenderer,
  },
};

export default function MenuComponent({ data }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <MenuStyle place={data.menu.place}>
      <div className='top-bar-big' onClick={() => navigate(-1)}>
        <h2 className='grow'> {data.menu.place}</h2>
        <button className='close' onClick={() => navigate(-1)}>
          ×
        </button>
      </div>
      <div className='grid'>
        <div className='icons-menu'>
          <ul>
            <li>
              <img src={vege} alt='icon vegetarisch' className='icon-menu' />

              {t("vegetarian")}
            </li>
            <li>
              <img src={vegan} alt='icon vegan' className='icon-menu' />
              {t("vegan")}
            </li>
          </ul>
        </div>
        <div className='menu-container'>
          {data?.menu?.menuSections?.map((section) => (
            <div className='menu-section' key={uuidv4()}>
              <h2 className='menu-title'>{section?.title}</h2>
              <ul className='sub-section'>
                {section?.menuInfoSection?.map((subSection) => (
                  <li key={uuidv4()}>
                    <div>
                      <h4>
                        {subSection?.title}{" "}
                        {subSection?.bio && (
                          <img src={bio} alt='icon bio' className='icon-menu' />
                        )}
                        {subSection?.vegan && (
                          <img
                            src={vegan}
                            alt='icon vegan'
                            className='icon-menu'
                          />
                        )}
                        {subSection?.vege && (
                          <img
                            src={vege}
                            alt='icon vegetarisch'
                            className='icon-menu'
                          />
                        )}
                      </h4>
                      <p className='menu-info'>{subSection.info}</p>
                    </div>
                    <p className='price'>{subSection.price}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <br />
          {data?.menu?._rawMenuInfo && (
            <div className='supplement'>
              <SanityBlockContent
                blocks={data.menu._rawMenuInfo}
                projectId='2le2eemj'
                dataset='production'
                className='supplement'
                serializers={serializers}
              />{" "}
            </div>
          )}
        </div>
        <div className='menu-container'>
          {data.menu.menuSections2.map((section) => (
            <div className='menu-section' key={uuidv4()}>
              <h2 className='menu-title'>{section.title}</h2>
              <ul className='sub-section'>
                {section.menuInfoSection.map((subSection) => (
                  <li key={uuidv4()}>
                    <div>
                      <h4>
                        {subSection.title}{" "}
                        {subSection.bio && (
                          <img
                            src={bio}
                            alt='icon bio'
                            className='icon-menu'
                            width='auto'
                            height='auto'
                          />
                        )}
                        {subSection.vegan && (
                          <img
                            src={vegan}
                            alt='icon vegan'
                            className='icon-menu'
                            width='auto'
                            height='auto'
                          />
                        )}
                        {subSection.vegetarian && (
                          <img
                            src={vege}
                            alt='icon vegetarisch'
                            className='icon-menu'
                            width='auto'
                            height='auto'
                          />
                        )}
                      </h4>
                      <p className='menu-info'>{subSection.info}</p>
                    </div>
                    <p className='price'>{subSection.price}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <br />
          {data.menu._rawMenuInfo2 && (
            <div className='supplement'>
              <SanityBlockContent
                blocks={data.menu._rawMenuInfo2}
                projectId='2le2eemj'
                dataset='production'
                serializers={serializers}
              />
            </div>
          )}
        </div>
      </div>
    </MenuStyle>
  );
}
