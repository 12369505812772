import React, { useEffect, useState } from "react";
import { ListBoxStyle } from "../styles/ListBoxStyle";

import Ball from "./Ball";
import { useGlobalData } from "../context/summerOrWinterContext";

export default function ListBox({ elem, place, mobile }) {
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  const { summerOrWinter } = useGlobalData();
  const eventDate = new Date(elem.eventDate);
  const currentDate = new Date();

  const dateDay = addZero(eventDate.getDate());
  const dateHour = addZero(eventDate.getHours());
  const dateMonth = addZero(eventDate.getMonth() + 1);
  const dateMinutes = addZero(eventDate.getMinutes());

  const noShow = () => {
    const diffTime = currentDate - eventDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert difference in milliseconds to days
    return diffDays >= 2; // Return true if eventDate was at least two days ago
  };

  return (
    <ListBoxStyle noShow={noShow()}>
      <a href={elem.linkEvent} target='_blank'>
        <div className='line-list'>
          <Ball
            size='small'
            place={place}
            summerOrWinter={summerOrWinter[0]?.summerOrWinter}
          />{" "}
          {elem.eventDate && (
            <>
              <h4>
                <span className='date-clean'>{dateDay}</span>/
                <span className='date-clean'>{dateMonth}</span>
              </h4>
              <h4 className='hour'>
                <span className='date-clean'>{dateHour}</span>:
                <span className='date-clean'>{dateMinutes}</span>
              </h4>
            </>
          )}
          <h4 className='title'>{elem.title}</h4>
          {!mobile && <h4>{elem.place}</h4>}
          {!mobile && elem.typeOfEvent && <h4> {elem.typeOfEvent} </h4>}
          {elem.linkEvent && (
            <h4 className='ticket'>
              Ticket <span className='Zarrows'>➝</span>
            </h4>
          )}
        </div>
      </a>
    </ListBoxStyle>
  );
}
