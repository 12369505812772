// gatsby-browser.js and gatsby-ssr.js
import React from "react";
import { GlobalDataProvider } from "./src/context/summerOrWinterContext"; // Make sure this path is correct
import Layout from "./src/components/Layout";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { TitleContext } from "./src/context/titleContext"; // Update with your path

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalDataProvider>
      <TitleContext.Provider value={{ title: "", setTitle: () => {} }}>
        <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
      </TitleContext.Provider>
    </GlobalDataProvider>
  );
};
