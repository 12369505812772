import styled from "styled-components";

export const MenuMobilStyle = styled.div`
  position: sticky;
  top: 0px;
  z-index: 5;

  .top-bar {
    border-bottom: 0;
  }

  .top-nav {
    height: 25px;

    padding: var(--marginvw);
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid var(--blue);
    a {
      height: 100%;
      width: 100%;
    }
  }

  .menu {
    div {
      text-transform: uppercase;
    }
    z-index: 4;
  }

  .info-kontakt {
    padding-bottom: 8vw;
    p {
      padding-bottom: 2vw;
    }
  }

  #menu-toggle {
    display: none;
  }

  .logo {
    height: 100%;
    width: auto;
  }

  .menu-button-container {
    display: flex;
    height: 100%;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu {
    border-top: 0.5px solid var(--blue);
    position: absolute;
    top: 0;
    margin-top: calc(25px + var(--marginvw) * 2);
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 25px);
    justify-content: center;
    align-items: center;
    display: none;

    padding: var(--marginvw);
    padding-bottom: 17vh;
    width: calc(100% - var(--marginvw) * 2);
    align-items: baseline;
    h2 {
      padding-bottom: 2vw;
    }
  }

  .menu-list {
    padding-top: 6vw;
  }

  #menu-toggle:checked ~ .menu {
    display: flex;
    justify-content: space-between;
    height: 75vh;
    background-color: white;
  }

  .footer {
    width: 100%;
    h2 {
      font-size: var(--p);
      font-family: "fontReg", sans-serif;
    }
  }

  .socialLinks {
    display: flex;
    align-items: center;
    justify-content: start;
    width: calc(80px + var(--marginvw));
    width: 100%;

    a {
      h4 {
        margin-bottom: 0;
      }
      background-color: var(--blue);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 10px; */
      height: 40px;
      width: 40px;
      text-transform: uppercase;
      border-radius: 50%;
      text-align: center;
      margin-right: 10px;
    }

    a.active {
      background-color: white;
      border: 1px solid var(--blue);
      color: var(--blue);
    }
  }
`;
