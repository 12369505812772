import { createGlobalStyle } from "styled-components";

import fontReg from "../assets/fonts/TTNorms-Regular.otf";
import fontBold from "../assets/fonts/TTNorms-Bold.otf";
import fontMed from "../assets/fonts/TTNorms-Medium.otf";
import LasloReg from "../assets/fonts/LasloWide-Regular.otf";
import LasloMed from "../assets/fonts/LasloWide-Bold.otf";
import AttilaLight from "../assets/fonts/AttilaSansClassic-Light.otf";
import AttilaReg from "../assets/fonts/AttilaSansClassic-Regular.otf";
import ZarrowsReg from "../assets/fonts/ZennerArrows-Regular.woff";

const Typography = createGlobalStyle`
  @font-face {
    font-family: "fontReg";
    src: url(${fontReg});
    font-display: swap;
  }
  @font-face {
    font-family: "fontMed";
    src: url(${fontMed});
    font-display: swap;
  }
  @font-face {
    font-family: "fontBold";
    src: url(${fontBold});
    font-display: swap;
  }

  @font-face {
    font-family: "LasloReg";
    src: url(${LasloReg});
    font-display: swap;
  }
  @font-face {
    font-family: "LasloMed";
    src: url(${LasloMed});
    font-display: swap;
  }

  @font-face {
    font-family: "AttilaLight";
    src: url(${AttilaLight});
    font-display: swap;
  }
  @font-face {
    font-family: "AttilaReg";
    src: url(${AttilaReg});
    font-display: swap;
  }
  @font-face {
    font-family: "ZennerArrows";
    src: url(${ZarrowsReg}) format('woff');
    font-display: swap;
  }

  html {
    font-family: "fontReg", sans-serif;
    color: var(--blackGreen);
    font-optical-sizing: none;

  }




  h1,h2 {
    font-family: "fontMed", sans-serif;
    margin: 0;

  }

  h3, h4, h5, p {
    font-family: "fontReg", sans-serif;

    margin: 0;
  }

  a {
    text-decoration: none;
    text-decoration-skip-ink: none;
  }

  .center {
    text-align: center;
  }

  ul {
    list-style: none;
  }

  p, ul {font-size: var(--p)}

  h2 {font-size: var(--h2); }

  h3 { font-size: var(--h3);
    text-transform: uppercase; }

  h4 {
      font-size: var(--h4);
      text-transform: uppercase;
    }

  h5 {
      text-decoration: underline;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }



  @media (max-width: 900px) {
    h4{
      font-size: 4vw;
    }
  }
`;

export default Typography;
