import React, { useState, useContext, useEffect } from "react";
import { FormStyle } from "../styles/FormStyle";
import useEmail from "../utils/useEmail";
import useForm from "../utils/useForm";
import { v4 as uuidv4 } from "uuid";
import SanityBlockContent from "@sanity/block-content-to-react";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import { useGlobalData } from "../context/summerOrWinterContext";

function MyLinkRenderer(props) {
  return (
    <a href={props.mark.href} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  );
}

const serializers = {
  marks: {
    link: MyLinkRenderer,
  },
};

export default function FormComponent({ data, pageContext }) {
  // const [allPlaces, setAllPlaces] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();
  let hoursOfToday = today.getHours(); // get current hour
  let minutes = today.getMinutes(); // get current minutes

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  if (hoursOfToday < 10) {
    hoursOfToday = "0" + hoursOfToday; // make sure hours has leading zero if less than 10
  }

  if (minutes < 10) {
    minutes = "0" + minutes; // make sure minutes has leading zero if less than 10
  }

  today = yyyy + "-" + mm + "-" + dd;

  let currentTime = hoursOfToday + ":" + minutes;

  let { values, updateValue } = useForm({
    name: "",
    email: "",
    allplaces:
      pageContext.name === "Mietanfrage"
        ? pageContext.place.toLowerCase() === "allplaces"
        : "",
    saal:
      pageContext.name === "Mietanfrage"
        ? pageContext.place.toLowerCase() === "saal" ||
          pageContext.place.toLowerCase() === "allplaces"
        : "",
    klub:
      pageContext.name === "Mietanfrage"
        ? pageContext.place.toLowerCase() === "klub" ||
          pageContext.place.toLowerCase() === "allplaces"
        : "",
    biergarten:
      pageContext.name === "Mietanfrage"
        ? pageContext.place.toLowerCase() === "biergarten" ||
          pageContext.place.toLowerCase() === "allplaces"
        : "",
    weingarten:
      pageContext.name === "Mietanfrage"
        ? pageContext.place.toLowerCase() === "weingarten" ||
          pageContext.place.toLowerCase() === "allplaces"
        : "",
    telefon: "",
    message: "",
    text: "",
    firma: "",
    anlass: "Anlass",
    date: today,
    timestart: currentTime,
    timeend: currentTime,
    number: "Personenanzahl",
    catering:
      pageContext.name === "Catering" ? "" : "Zusätzliches Catering gewünscht?",
  });

  // const validateForm = () => {
  //   const fieldsToValidate = [
  //     "telefon",
  //     "text",
  //     "firma",
  //     "anlass",
  //     "date",
  //     "time",
  //     "number",
  //     "name",
  //     "email",
  //     "catering"
  //   ];

  //   const placeFields = [
  //     "allplaces",
  //     "saal",
  //     "klub",
  //     "biergarten",
  //     "weingarten",
  //   ];

  //   for (let key of fieldsToValidate) {
  //     if (values[key] === "") {
  //       return `Hoppla! ${values[key]};-) `;
  //     }
  //   }

  //   const placeChecked = placeFields.some((key) => values[key] !== "");
  //   if (!placeChecked) {
  //     return `Hoppla! check a places `;
  //   }

  //   return false;
  // };

  const { messageSuccess, error, loading, submitMessage, errorMessage } =
    useEmail({
      values,
      formName: pageContext.name,
    });

  const minDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const checkBoxPlaces = document.querySelectorAll(".check-place input");

    checkBoxPlaces.forEach((input) =>
      input.id === pageContext.place.toLowerCase() ||
      pageContext.place.toLowerCase() === "allplaces"
        ? (input.checked = true)
        : (input.checked = false)
    );
  }, []);

  const { summerOrWinter } = useGlobalData();
  return (
    <>
      <FormStyle onSubmit={submitMessage}>
        <fieldset disabled={loading}>
          <div
            className='topBox'
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            <h2>
              {pageContext.name === "Catering"
                ? "Catering"
                : `${pageContext.name} `}
              {/* {pageContext.name !== "Catering" && pageContext.place} */}
            </h2>
            <button className='close'>×</button>
          </div>
          {messageSuccess && <p className='success'>{t("sucess send")}</p>}
          {!messageSuccess && (
            <>
              <div className='info-box'>
                {data.form._rawText && (
                  <SanityBlockContent
                    blocks={data.form._rawText}
                    projectId='2le2eemj'
                    dataset='production'
                    serializers={serializers}
                  />
                )}
              </div>
              {pageContext.name === "Mietanfrage" && (
                <div className='check-place'>
                  <label>
                    <input
                      type='checkbox'
                      id='allplaces'
                      name='allplaces'
                      checked={values.allplaces}
                      onChange={updateValue}
                    />
                    <div className='box-check'>
                      {" "}
                      <p>Alle</p>
                    </div>
                  </label>
                  <label>
                    <input
                      type='checkbox'
                      id='saal'
                      name='saal'
                      checked={values.saal}
                      onChange={updateValue}
                    />
                    <div className='box-check'>
                      <p> Saal</p>
                    </div>
                  </label>
                  <label>
                    {" "}
                    <input
                      type='checkbox'
                      id='klub'
                      name='klub'
                      checked={values.klub}
                      onChange={updateValue}
                    />
                    <div className='box-check'>
                      <p> Klub</p>
                    </div>
                  </label>
                  <label>
                    {" "}
                    <input
                      type='checkbox'
                      id='biergarten'
                      name='biergarten'
                      checked={values.biergarten}
                      onChange={updateValue}
                    />
                    <div className='box-check'>
                      <p>
                        {summerOrWinter[0]?.summerOrWinter === "Summer"
                          ? "Biergarten"
                          : "Wintergarten"}
                      </p>
                    </div>
                  </label>
                  <label>
                    {" "}
                    <input
                      type='checkbox'
                      id='weingarten'
                      name='weingarten'
                      checked={values.weingarten}
                      onChange={updateValue}
                    />
                    <div className='box-check'>
                      {" "}
                      <p>Weingarten</p>
                    </div>
                  </label>
                </div>
              )}
              <input
                type='text'
                placeholder='Name'
                name='name'
                id='name'
                value={values.name}
                onChange={updateValue}
              />
              <input
                type='text'
                placeholder={t("firma")}
                name='firma'
                id='firma'
                value={values.firma}
                onChange={updateValue}
              />
              <input
                type='telefon'
                placeholder={t("telefon")}
                name='telefon'
                id='telefon'
                value={values.telefon}
                onChange={updateValue}
              />
              <input
                type='email'
                placeholder='Email'
                name='email'
                id='email'
                value={values.email}
                onChange={updateValue}
              />
              <select
                value={values.anlass}
                name='anlass'
                onChange={updateValue}
              >
                <option value='Anlass' disabled hidden>
                  {t("occasion")}
                </option>
                {data.form.reasonsList.map((reason) => (
                  <option value={reason} key={uuidv4()}>
                    {reason}
                  </option>
                ))}
              </select>
              <br></br>
              <label htmlFor='date'>{t("date")}</label>
              <input
                type='date'
                name='date'
                id='date'
                min={minDate}
                value={values.date}
                onChange={updateValue}
                className='date'
              />
              <br></br>
              <label htmlFor='timestart'>{t("start at")}</label>
              <input
                type='time'
                name='timestart'
                id='timestart'
                placeholder={t("start at")}
                // value={values.time}
                onChange={updateValue}
                className='time'
              />
              <br></br>
              <label htmlFor='timeend'>{t("end at")}</label>
              <input
                type='time'
                name='timeend'
                id='timeend'
                placeholder={t("end at")}
                // value={values.time}
                onChange={updateValue}
                className='time'
              />

              <select
                value={values.number}
                name='number'
                placeholder='Personenanzahl'
                onChange={updateValue}
              >
                <option
                  className='selected'
                  value='Personenanzahl'
                  disabled
                  hidden
                >
                  {t("num personnes")}
                </option>
                {pageContext.name === "Catering" && (
                  <>
                    <option value='80-150'>80-150</option>
                    <option value='150-250'>150-250</option>
                    <option value='250-500'>250-500</option>
                  </>
                )}
                {pageContext.name === "Mietanfrage" && (
                  <>
                    <option value='100-200'>100-200</option>
                    <option value='200-500'>200-500</option>
                    <option value='500-1000'>500-1000</option>
                    <option value='500-1000'>1000-2500</option>
                  </>
                )}
              </select>
              {pageContext.name !== "Catering" && (
                <select
                  value={values.catering}
                  name='catering'
                  onChange={updateValue}
                >
                  <option
                    value='Zusätzliches Catering gewünscht?'
                    disabled
                    hidden
                  >
                    {t("add catering")}
                  </option>
                  <option value='Ja'> {t("ja")}</option>
                  <option value='Nein'>{t("nein")}</option>
                </select>
              )}
              <textarea
                name='text'
                id='text'
                value={values.text}
                onChange={updateValue}
                placeholder='Text'
                className='text'
              />
            </>
          )}
        </fieldset>

        {!messageSuccess && (
          <fieldset disabled={loading}>
            <div>
              {error || errorMessage ? (
                <p className='error'>Error: {t("missing field")}</p>
              ) : (
                ""
              )}
              {messageSuccess ? (
                <p className='success'>{t("sucess send")}</p>
              ) : (
                ""
              )}
            </div>
            <button
              className='senden'
              type='submit'
              onSubmit={submitMessage}
              disabled={loading || messageSuccess}
            >
              {loading ? <p> {t("sending email")}</p> : <p>{t("senden")}</p>}
            </button>
          </fieldset>
        )}
      </FormStyle>
    </>
  );
}
