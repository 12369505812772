import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
import ListSearch from "./ListSearch";
const searchClient = algoliasearch(
  "WJ533DXBBS",
  "130fecb5438ace336edc7e1f64c9104d"
);
export default function SearchColumnBox({ language }) {
  const [searchQuery, setSearchQuery] = useState("");

  // useEffect(() => {
  //   if (searchQuery === "") {
  //     document.querySelector(".ais-Hits").style.display = "none";
  //   } else {
  //     document.querySelector(".ais-Hits").style.display = "block";
  //   }
  // }, [searchQuery]);

  useEffect(() => {
    // This will run every time the language changes
    setSearchQuery(""); // This will clear the search
  }, [language]);

  return (
    <div className='search'>
      <InstantSearch
        searchClient={searchClient}
        indexName='zenner'
        onSearchStateChange={(searchState) => {
          setSearchQuery(searchState.query);
        }}
      >
        <SearchBox />
        {searchQuery && <Hits hitComponent={ListSearch} />}
      </InstantSearch>
    </div>
  );
}
