import React, { useState } from "react";
import { PlacesColumnBoxStyle } from "../styles/PlacesColumnBoxStyle";
import InfoColumn from "./InfoColumn";

export default function InfoColumnBox({ data }) {
  const [bigScreen, setBigScreen] = useState(false);
  const [bigPlace, setBigPlace] = useState("");

  const renderInfoColumn = (place) => {
    return (
      <InfoColumn
        setBigScreen={setBigScreen}
        bigScreen={bigScreen}
        bigPlace={bigPlace}
        place={place}
        data={data}
        setBigPlace={setBigPlace}
      />
    );
  };

  return (
    <PlacesColumnBoxStyle big={bigScreen} numColumn={3} className='contentBox'>
      {renderInfoColumn("About")}
      {renderInfoColumn("Info/Kontakt")}
      {renderInfoColumn("Historie")}
    </PlacesColumnBoxStyle>
  );
}
