import styled from "styled-components";
export const PlacesColumnBoxStyle = styled.div`
  p,
  a,
  input,
  textarea,
  select {
    font-size: ${(props) => {
      if (props.big) {
        return "2.0vw";
      }
      if (props.numColumn === 7) {
        return "0.75vw";
      }
      if (props.numColumn === 6) {
        return "0.9vw";
      }
      if (props.numColumn === 5) {
        return "0.9vw";
      }

      if (props.numColumn === 4) {
        return "0.9vw";
      }
      if (props.numColumn === 3) {
        return "0.9vw";
      }
      if (props.numColumn === 2) {
        return "0.9vw";
      }
    }};

    margin-bottom: ${(props) => {
      if (props.big) {
        return " calc(var(--p) / 1)";
      }
      // if (props.numColumn === 7) {
      //   return "0.75vw";
      // }
      // if (props.numColumn === 5) {
      //   return "0.8vw";
      // }
      // if (props.numColumn === 4) {
      //   return "0.9vw";
      // }
      // if (props.numColumn === 3) {
      //   return "0.9vw";
      // }
    }};
  }

  h4 {
    font-size: ${(props) => {
      if (props.big) {
        return "2.0vw";
      }
      if (props.numColumn === 7) {
        return "0.75vw";
      }
      if (props.numColumn === 6) {
        return "0.9vw";
      }
      if (props.numColumn === 5) {
        return "0.9vw";
      }

      if (props.numColumn === 4) {
        return "0.9vw";
      }
      if (props.numColumn === 3) {
        return "0.9vw";
      }
      if (props.numColumn === 2) {
        return "0.9vw";
      }
    }};
  }

  h5 {
    font-size: ${(props) => {
      if (props.big) {
        return "1.4vw";
      }
      if (props.numColumn === 7) {
        return "0.75vw";
      }
      if (props.numColumn === 5) {
        return "0.8vw";
      }
      if (props.numColumn === 4) {
        return "0.9vw";
      }
      if (props.numColumn === 3) {
        return "0.9vw";
      }
    }};
  }

  h3 {
    text-align: center;
    margin-bottom: var(--marginvw);
    font-size: ${(props) => {
      if (props.big) {
        return "2.5vw";
      }
      if (props.numColumn === 7) {
        return "0.75vw";
      }
      if (props.numColumn === 5) {
        return "1.2vw";
      }
      if (props.numColumn === 4) {
        return "1.2vw";
      }
      if (props.numColumn === 3) {
        return "1.2vw";
      }
    }};
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: ${(props) => {
      if (props.big) {
        return "13vw";
      }
      if (props.numColumn === 7) {
        return "3vw";
      }
      if (props.numColumn === 5) {
        return "5vw";
      }
      if (props.numColumn === 4) {
        return "6vw";
      }
      if (props.numColumn === 3) {
        return "6vw";
      }
    }};
  }

  .small {
    height: ${(props) => {
      if (props.big) {
        return "2vw";
      }
      if (props.numColumn === 7) {
        return "0.8vw";
      }
      if (props.numColumn === 5) {
        return "1vw";
      }
      if (props.numColumn === 4) {
        return "1vw";
      }
      if (props.numColumn === 3) {
        return "1vw";
      }
    }};
    width: ${(props) => {
      if (props.big) {
        return "2vw";
      }
      if (props.numColumn === 7) {
        return "0.8vw";
      }
      if (props.numColumn === 5) {
        return "1vw";
      }
      if (props.numColumn === 4) {
        return "1vw";
      }
      if (props.numColumn === 3) {
        return "1vw";
      }
    }};
  }

  .gatsby-image-wrapper.portrait {
    height: ${(props) => {
      if (props.big) {
        return "100%";
      }
      if (props.numColumn === 7) {
        return "12vw";
      }
      if (props.numColumn === 5) {
        return "18vw";
      }
      if (props.numColumn === 4) {
        return "21vw";
      }
      if (props.numColumn === 3) {
        return "29vw";
      }
    }};
  }

  .gatsby-image-wrapper.landscape {
    height: ${(props) => {
      if (props.big) {
        return "100%";
      }
      if (props.numColumn === 7) {
        return "8vw";
      }
      if (props.numColumn === 5) {
        return "12vw";
      }
      if (props.numColumn === 4) {
        return "15vw";
      }
      if (props.numColumn === 3) {
        return "20vw";
      }
    }};
  }

  .gatsby-image-wrapper.carré {
    height: ${(props) => {
      if (props.big) {
        return "100%";
      }
      if (props.numColumn === 7) {
        return "10vw";
      }
      if (props.numColumn === 5) {
        return "15vw";
      }
      if (props.numColumn === 4) {
        return "20vw";
      }
      if (props.numColumn === 3) {
        return "28vw";
      }
      if (props.numColumn === 2) {
        return "42vw";
      }
    }};
  }

  .sanity-block p {
    margin-bottom: ${(props) => {
      if (props.big) {
        return "2vw";
      }
    }};
  }

  @media (max-width: 900px) {
  }
`;
