import styled from "styled-components";

export const MenuStyle = styled.div`
  width: 100%;
  height: calc(100vh - var(--marginvw) * 2);

  border-left: 0.5px solid;

  .grid {
    background-color: ${(props) => {
      if (props.place === "Biergarten") {
        return "#A0C8DC";
      }
      if (props.place === "Eisdiele") {
        return "#F4D9D7";
      }
      if (props.place === "Weingarten") {
        return "#ED6E1A";
      }
      if (props.place === "Pop-Up Bistro") {
        return "#d7e2d7";
      }
    }};
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
  }
  .top-bar-big {
    height: 3vh;
    padding-bottom: var(--marginvw);
    margin: 0px var(--marginvw);
    width: calc(100% - var(--marginvw));
    background-color: white;
    border-bottom: 0.5px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .grow {
      flex-grow: 1;
    }
  }

  .close {
    background-color: white;
    border: 0.5px solid var(--blue);
    border-radius: 50%;
    font-size: 1.35vw;
    width: 1.5vw;
    height: 1.5vw;
    line-height: 0%;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    :hover {
      background-color: var(--blue);
      color: white;
    }
  }

  .icons-menu {
    position: fixed;
    right: calc(var(--marginvw) * 3);

    padding: var(--marginvw);
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .menu-container {
    height: 92%;
    overflow: hidden;
    overflow-y: scroll;
    padding: calc(var(--marginvw) * 4);
    h2 {
      font-family: ${(props) => {
        if (props.place === "Biergarten") {
          return "LasloReg";
        }
        if (props.place === "Weingarten") {
          return "AttilaReg";
        }
        if (props.place === "Eisdiele") {
          return "fontReg";
        }
      }};
      padding-bottom: 2vw;
      font-size: 2vw;
    }
    h4 {
      font-family: ${(props) => {
        if (props.place === "Biergarten") {
          return "fontReg";
        }
        if (props.place === "Weingarten") {
          return "fontReg";
        }
        if (props.place === "Eisdiele") {
          return "fontReg";
        }
      }};
      text-transform: unset;
    }
    li {
      margin-bottom: var(--marginvw);
    }
  }

  .menu-section {
    margin-top: 4vw;
    :first-child {
      margin-top: 0;
    }
  }

  .menu-info,
  .price,
  .supplement p {
    font-size: 0.8vw;
    margin-top: 0.2vw;
  }

  h2 {
    text-align: center;
  }
  .menu-title {
    text-align: left;
  }
  .sub-section {
    width: 100%;
    li {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .icon-menu {
      margin-left: 0.5vw;
      margin-right: 0;
    }
  }

  .icon-menu {
    width: 1vw;
    margin-right: var(--marginvw);
  }

  .price {
    text-align: right;
    width: 25%;
  }

  @media (max-width: 900px) {
    height: inherit;
    .grid {
      height: 75vh;
      overflow: scroll;
      padding-top: 3rem;
      display: block;
      position: relative;
    }
    .icon-menu {
      width: 2vh;
      margin-right: 0.5vw;
    }
    .menu-container {
      padding-top: 6vh;
      padding: var(--marginvw);
      height: auto;
      overflow: hidden;
      h2 {
        padding-bottom: 6vw;
        font-size: 8vw;
      }
    }

    .menu-section {
      margin-top: 15vw;
    }

    .menu-info {
      font-size: 3.3vw;
      margin-top: 0.2vw;
      padding-right: 0;
    }

    .price {
      font-size: 2.9vw;
      text-align: left;
      margin-top: 0.2vw;
      width: 100%;
    }

    .supplement p {
      font-size: 2.9vw;
      margin-top: 0.2vw;
    }

    .top-bar-big {
      display: none;
    }

    .sub-section li {
      display: block;
    }

    .icons-menu {
      position: fixed;
      top: 7rem;
      right: calc(var(--marginvw) * 0);

      ul {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
      }

      li:last-child {
        padding-left: 1rem;
      }
    }
  }
`;
