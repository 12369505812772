import { useState } from "react";

export default function useForm(defaults) {
  const [values, setValues] = useState(defaults);
  const [check, setcheck] = useState(false);

  function updateValue(e) {
    e.currentTarget.style.color = "#1e2e53";

    let { value } = e.target;

    if (e.target.type === "number") {
      value = parseInt(e.target.value);
    }

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }

    if (
      e.currentTarget.id === "klub" ||
      e.currentTarget.id === "saal" ||
      e.currentTarget.id === "biergarten" ||
      e.currentTarget.id === "weingerten"
    ) {
      if (!value) {
        values.allplaces = false;
      }
    }

    if (e.currentTarget.id === "allplaces") {
      if (value) {
        values.saal = true;
        values.klub = true;
        values.biergarten = true;
        values.weingarten = true;
      } else {
        values.saal = false;
        values.klub = false;
        values.biergarten = false;
        values.weingarten = false;
      }
    }

    setValues({
      // copy the existing values into it
      ...values,
      // update the new value that changed
      [e.target.name]: value,
    });
  }

  return { values, updateValue };
}
