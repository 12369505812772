import styled from "styled-components";

export const GridStyle = styled.div`
  display: grid;
  grid-template-columns: 13vw 1fr;
  height: calc(100vh - var(--marginvw) * 2);
  overflow: hidden;
  padding: var(--marginvw);
  .btn-cathering {
    transform: rotate(-90deg);
    z-index: 22222222222222;
    height: 5vw;
    transform-origin: unset;
    width: fit-content;
    position: fixed;
    right: -5vw;
    top: 22vh;
    background-color: var(--blue);
    padding: var(--marginvw) 1.5rem 0rem 1.5rem;
    border-radius: 1.5rem;
    color: white;
    border: 0.5px solid white;
    text-transform: uppercase;
    :hover {
      color: var(--blue);
      background-color: white;
      border: 0.5px solid var(--blue);
    }
    h3 {
      font-size: 1.25vw;
    }
  }

  .contentBox {
    display: flex;
    width: 100%;
    height: inherit;
    overflow: hidden;
  }

  .home {
    border-left: 0.5px solid var(--blue);
    width: 100%;
    position: relative;
    overflow: hidden;
    .hover {
      width: 100%;
      overflow: scroll;
      height: 99vh;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      div {
        height: 100%;
        width: 100%;
      }
    }
  }

  .news-box {
    display: flex;
    overflow: hidden;
    animation: move 19s linear infinite 0.5s;
    width: max-content;
    padding-left: var(--marginvw);
  }

  .marque {
    text-transform: uppercase;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--h2);
    font-style: italic;
    padding-bottom: var(--marginvw);
    /* border-bottom: 1px solid var(--blue);
    margin-bottom: var(--marginvw); */
    /* width: 100%; */
  }

  @keyframes move {
    0% {
      transform: translate3d(0%, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .news-box:hover {
    animation-play-state: paused;
  }

  .button {
    h4 {
      display: flex;
      align-items: center;
    }
  }

  .slick-arrow {
    margin-top: -1.5vh;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-next {
    /* cursor: url(../assets/zenner-elements/right-arrow.png); */
    font-family: ZennerArrows;
    right: 0;
    ::before {
      content: ">";
    }
  }
  .slick-prev {
    font-family: ZennerArrows;
    z-index: 1;
    left: 0px;
    /* cursor: url('../assets/zenner-elements/left-arrow.png'); */
    ::before {
      content: "<";
    }
  }

  .slick-dots {
    display: none !important;
  }

  .slick-prev,
  .slick-next {
    width: auto;
    height: auto;
    opacity: 1;
  }
  .slick-prev::before,
  .slick-next::before {
    font-family: ZennerArrows;
    color: var(--blue);
    display: flex;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .column {
    width: 100%;
    border-left: 0.5px solid var(--blue);
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }
`;
