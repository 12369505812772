import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { navigate } from "gatsby";
import Image from "./Image";

export const MapStyle = styled.div`
  width: 100%;
  height: 100%;

  border-left: 0.5px solid;

  .top-bar-big {
    height: 3vh;
    padding-bottom: var(--marginvw);
    margin: 0px var(--marginvw);
    width: calc(100% - var(--marginvw));
    background-color: white;
    border-bottom: 0.5px solid;
    display: flex;
    align-items: center;
    h2 {
      flex-grow: 1;
    }
  }

  .close {
    background-color: white;
    border: 0.5px solid var(--blue);
    border-radius: 50%;
    font-size: 1.35vw;
    width: 1.5vw;
    height: 1.5vw;
    line-height: 0%;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    :hover {
      background-color: var(--blue);
      color: white;
    }
  }

  h2 {
    text-align: center;
  }

  .map {
    height: 93vh;
    padding-bottom: 0;
    img {
      object-fit: cover !important;
      object-position: top !important;
    }
  }

  .top-bar-big {
    cursor: pointer;
  }
  @media (max-width: 900px) {
    .top-bar-big {
      display: none;
    }

    .map {
      height: inherit;
    }
  }
`;

export default function MapComponent({ data }) {
  return (
    <MapStyle>
      <div className='top-bar-big' onClick={() => navigate(-1)}>
        <h2>Zenner Map</h2>
        <button className='close' onClick={() => navigate(-1)}>
          ×
        </button>
      </div>
      <div>
        <Image image={data.map.nodes[0].map} carousselType='map' />
      </div>
    </MapStyle>
  );
}
