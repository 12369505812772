import { useState } from 'react';

export default function useSendNewsletter({ values }) {
  const [errorNews, setError] = useState();
  const [loadingNews, setLoading] = useState(false);
  const [messageSuccessNews, setMessageSuccess] = useState('');

  async function submitNewsletter(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // // gather all the data
    const body = values;

    // // 4. Send this data the a serevrless function when they check out
    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/sendInBlue`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
    const text = JSON.parse(await res.text());

    // check if everything worked
    if (res.status >= 400 && res.status < 600) {
      setLoading(false); // turn off loading
      setError(text.message);
    } else {
      // it worked!
      setLoading(false);
      setMessageSuccess('Success!');
    }
  }

  return {
    messageSuccessNews,
    errorNews,
    loadingNews,
    submitNewsletter,
  };
}
